import { validate } from 'jsonschema';
import retrieveIDSchema from '../../retrieveIDSchema';

export default Base =>
  class SchemaField extends Base {
    render() {
      const {
        props,
        props: {
          formData,
          onChange,
          schema: { oneOf, title },
          uiSchema: {
            'ui:field': field = () => (
              <SchemaField
                {...props}
                _update={
                  // Force update when formData changes.
                  formData
                }
                onChange={value => onChange(oneOf[value].default)}
                schema={{
                  title,
                  type: 'integer',
                  enum: [...oneOf.keys()],
                  enumNames: oneOf.map(({ title }) => title)
                }}
                uiSchema={{}}
              />
            )
          }
        }
      } = this;
      const ctx = oneOf
        ? formData === undefined
          ? {
              ...this,
              props: {
                ...props,
                schema: { type: 'any' },
                uiSchema: {
                  'ui:field': field
                }
              }
            }
          : do {
              const props = {
                ...this.props,
                schema:
                  oneOf.find(
                    schema => !validate(formData, schema).errors.length
                  ) ||
                  do {
                    console.error(
                      new Error('No match to `oneOf` schema items.')
                    );
                    ({});
                  }
              };
              ({
                ...this,
                props: {
                  ...props,
                  idSchema: retrieveIDSchema(props)
                }
              });
            }
        : this;
      return ctx::super.render();
    }
  };
