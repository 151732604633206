import { Component } from 'react';
import { FormGroup, FormControl } from 'react-bootstrap';
import FIELD from '../../../../constants/fieldIDs';
import { NUMBER, LETTER, BMI } from '../../../../constants/pointTypes';
import { Consumer } from '../../../../utils/react-jsonschema-form/Form/FormData';

/**
 * For formgroup use only
 *
 * Show the points sum of a formgroup.
 * Point types are:
 * - Number
 *    Field values have numeral values associated with them. Sums these numbers up and shows the user the sum result. Can be configured with pointTresholds to show
 *    a text value with point tresholds instead of point sum, for example: 5+ points -> show "You scored more than 5 points"
 *
 *    Tresholds can also contain a letter value as with the 'letter' point type.
 *    For example: 5+ points from the formgroup -> "You scored more than 5 points" -> show the professional the patient scored "AB"
 *
 * - Letter
 *    Field values have letter based values associated with them. Sum these letters up and show unique letters the user has gotten from their answers
 *    For example: Multivalue select -> user selects options 1 and 2, which have the letters "AB" and "BC" associated with them. As the sum, show the professional that the
 *    patient scored "ABC"
 *
 * - BMI
 *    Only works with BMI formgroup, and this formgroup needs to have 1st field: height (in centimeters) and 2nd field: weight
 */

export default class PointsDisplay extends Component {
  static defaultProps = {
    value: 0,
    options: {
      pointsType: 0,
      pointTresholds: []
    }
  };

  state = {};

  static getDerivedStateFromProps = (props) => {
    const pointTresholds = Array.isArray(props.options.pointTresholds) ? props.options.pointTresholds : [props.options.pointTresholds];
    return {
      schema: props.schema,
      value: 0,
      pointsType: props.options.pointsType,
      pointTresholds: pointTresholds.filter(pt => pt).map(pt => pt.split(";"))
    }
  };

  sumPoints = (values, schema) => {
    const fgValues = values[parseInt(schema.index)];
    let sum = 0;

    schema.formgroupFields.forEach((field, index) => {
      if (!field) {
        return;
      }
      const fieldVal = fgValues[index];
      if (fieldVal === null || fieldVal === undefined) {
        return;
      }
      const fieldPoints = field[FIELD.CHOICES_POINTS];
      if (Array.isArray(fieldVal)) {
        fieldVal.forEach(value => {
          const answerPoints = parseInt(fieldPoints?.[parseInt(value)]);
          if (answerPoints > 0) {
            sum = sum + answerPoints;
          }
        });
      } else {
        const answerPoints = parseInt(fieldPoints?.[parseInt(fieldVal)]);
        if (answerPoints > 0) {
          sum = sum + answerPoints;
        }
      }
    });
    return this.getNumbersDisplayValue(sum);
  };

  sumLetters = (values, schema) => {
    const fgValues = values[parseInt(schema.index)];
    let sum = [];

    schema.formgroupFields.forEach((field, index) => {
      if (!field) {
        return;
      }
      const fieldVal = fgValues[index];
      if (fieldVal === null || fieldVal === undefined) {
        return;
      }
      const fieldPoints = field[FIELD.CHOICES_POINTS];
      if (Array.isArray(fieldVal)) {
        fieldVal.forEach(val => {
          const answerLetters = fieldPoints?.[val];
          answerLetters?.split('').forEach(letter => {
            if (sum.indexOf(letter) === -1) {
              sum.push(letter);
            }
          });
        })
      } else {
        const answerLetters = fieldPoints?.[fieldVal];
        answerLetters?.split('').forEach(letter => {
          if (sum.indexOf(letter) === -1) {
            sum.push(letter);
          }
        });
      }
    });
    return this.getLettersDisplayValue(sum);

  };

  sumBMI = (values, schema) => {
    const fgValues = values[parseInt(schema.index)];
    if (fgValues.length < 2 || fgValues[0] === undefined || fgValues[1] === undefined) {
      return this.getBMIDisplayValue(0);
    }

    const length = parseFloat(fgValues[0]) / 100;
    const weight = parseFloat(fgValues[1]);

    const sum = weight / length / length;
    return this.getBMIDisplayValue(sum.toFixed(1));
  };

  getNumbersDisplayValue = (sum) => {

    const pointTresholds = this.state.pointTresholds
      .filter(pt => !!parseInt(pt[0]) || parseInt(pt[0]) === 0)
      .sort((pt1, pt2) => parseInt(pt1[0]) > parseInt(pt2[0]) ? 0 : 1);

    if (pointTresholds.length < 1) {
      return "Kokonaispistemääräsi on: " + sum;
    }
    for (let i = 0; i < pointTresholds.length; i++) {
      const tresholdVal = pointTresholds[i][1].split("|");
      if (pointTresholds[i][0] <= sum && tresholdVal[0] !== "") {
        return tresholdVal;
      }
    }
    return "Kokonaispistemääräsi on: " + sum;
  };

  getLettersDisplayValue = (sum) => {
    return sum.sort().join("");
  };

  getBMIDisplayValue = (sum) => {
    const pointTresholds = this.state.pointTresholds
      .filter(pt => !!parseInt(pt[0]) || parseInt(pt[0]) === 0)
      .sort((pt1, pt2) => parseInt(pt1[0]) > parseInt(pt2[0]) ? 0 : 1);

    if (pointTresholds.length < 1) {
      return "BMI-arvosi on: " + sum;
    }

    for (let i = 0; i < pointTresholds.length; i++) {
      if (pointTresholds[i][0] <= sum) {
        //return pointTresholds[i][1];
        return "Painoindeksi (BMI) = " + sum + " kg/m2";
      }
    }
    return "Painoindeksi (BMI) = " + sum + " kg/m2";
  };

  render() {
    return (
      <Consumer>
        {(context) => {
          let sum;
          switch (this.state.pointsType) {
            case NUMBER:
              sum = this.sumPoints(context.formData, this.props.schema);
              break;
            case LETTER:
              sum = this.sumLetters(context.formData, this.props.schema);
              break;
            case BMI:
              sum = this.sumBMI(context.formData, this.props.schema);
          }
          return (
            sum !== null && <FormGroup>
              <FormControl
                type="string"
                value={sum}
                disabled
              />
            </FormGroup>
          )}}
      </Consumer>
    );
  }
}
