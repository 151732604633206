import React from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { FIELD as FORM_ELEMENTS } from '../forms';
import ButtonPrint from './ButtonPrint';
import withLocalize from './utils/withLocalize';
import messages from './intl/messages.properties';
import { Fields } from './Pages/Values/View/Form/Fields';

export default (({ OtherButtons, design, localize, values, fields, formGroups }) => (
  <>
    <h1>
      <FormattedMessage id={messages.submitWasSuccessful} />
    </h1>

    <div className='success-text-container'>
      <ReactMarkdown source={localize(design[FORM_ELEMENTS.SUCCESS_TEXT])} />
    </div>

    <h2>
      <FormattedMessage id={messages.values} />
    </h2>

    <Fields elements={design[FORM_ELEMENTS.ELEMENTS]} values={values} fields={fields} formGroups={formGroups} />

    <ButtonToolbar>
      <OtherButtons />
      <ButtonPrint />
    </ButtonToolbar>
  </>
)) |> withLocalize;
