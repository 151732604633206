import { FIELD } from '../../../../forms';

export default ({
  [String(FIELD.ELEMENTS)]: elements,
  [String(FIELD.NOTES)]: notes,
  [String(FIELD.ASSIGNABLE)]: assignable,
  [String(FIELD.SUCCESS_TEXT)]: successText,
  [String(FIELD.TITLE)]: title,
  [String(FIELD.SHOW_LETTERS)]: showLetters,
  ...rest
}) => ({
  ...rest,
  elements,
  notes,
  assignable,
  successText,
  title,
  showLetters
});
