import connect from '../../../utils/react-refetch/connectAndRenderOnFulfilled';

export default ComposedComponent =>
  connect(({ id, rev }) => ({
    formGroupsFetch: `/api/formgroup/forForm/${id}/${rev}`
  }))(({ formGroupsFetch, ...props }) => (
    <ComposedComponent
      {...props}
      formGroups={formGroupsFetch}
    />
  ));
