import { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { queue } from 'async';
import 'intl';
import fetchLocaleData from './fetchLocaleData';
import { translations } from '.';

export default class IntlProviderWrapper extends Component {
  switchLocale = queue(async (locale, callback) => {
    if (this.state.locale !== locale) {
      // If translations for selected locale are available.
      // Bypass in development mode to always fetch possible changes.
      if (process.env.NODE_ENV === 'production' && translations.has(locale)) {
        this.setLocale(locale);
      } else {
        await fetchLocaleData(locale);
        this.setLocale(locale);
      }
    }
    callback();
  }).push;

  state = {};

  componentDidMount() {
    this.switchLocale(this.props.locale);
  }

  componentDidUpdate() {
    this.switchLocale(this.props.locale);
  }

  setLocale(locale) {
    this.setState({ locale });
    this.props.onChangeLocale?.(locale);
  }

  render() {
    const { locale } = this.state;
    return locale ? (
      <IntlProvider
        // Force update when location changes.
        _forceUpdate={{}}
        {...this.props}
        locale={locale}
        messages={translations.get(locale)}
      />
    ) : null;
  }
}
