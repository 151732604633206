import { getHost } from './utils/react-refetch';

export const DEVELOPMENT = 'development';
export const MOCK = 'mock';
export const PRODUCTION = 'production';

export default (process.env.NODE_ENV === 'development'
  ? getHost()
    ? DEVELOPMENT
    : MOCK
  : PRODUCTION);
