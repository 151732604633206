import { Component } from 'react';
import { FormControl, Row, Col, ControlLabel } from 'react-bootstrap';

export default class MapItem extends Component {
  static defaultProps = {
    value: "",
    labels: ""
  };

  state = {};

  static getDerivedStateFromProps = (props, state) => {
    const {value} = props;
    return {
      schema: props.schema,
      value: state?.value || value?.split(";") || ["",""],
      labels: (props.schema.labels?.split(";").length === 2 && props.schema.labels.split(";")) || ["", ""]
    }
  }

  ProcessValue = (event) => {
    const newState = {...this.state};
    newState.value[event.target.name] = event.target.value;
    this.setState(newState);
    this.props.onChange(newState.value.join(";"));
  };

  render() {
    return (
            <Row>
              <Col xs={6}>
                  <ControlLabel>
                    {this.state.labels[0]}
                  </ControlLabel>
                  <FormControl
                    type="string"
                    name={0}
                    value={this.state.value[0]}
                    onChange={this.ProcessValue}
                  />
                </Col>
                <Col xs={6}>
                  <ControlLabel>
                    {this.state.labels[1]}
                  </ControlLabel>
                  <FormControl
                    type="string"
                    name={1}
                    value={this.state.value[1]}
                    onChange={this.ProcessValue}
                  />
                </Col>
            </Row>
    );
  }
}
