import { withRouter } from 'react-router-dom';
import { ListGroupItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import FormattedDatetime from '../../../utils/FormattedDatetime';
import FormName from '../FormName';
import UserInfo from './UserInfo';
import React from 'react';

export default (({ match: { path }, item: { _id, form, timestamp, user }, forms, assignedForms }) => {
  const assignedForm = assignedForms.find(f => f.formId === form.id && f.formRev === form.rev)
  const revTitle = assignedForm?.hasOwnProperty('revTitle') ? assignedForm.revTitle : null

  return (
    <LinkContainer to={`${path}/${_id}/view`}>
      <ListGroupItem>
        <FormName form={forms.find(f => f._id === form.id)}/>{' '}

        {revTitle ? `(${revTitle})` : null}{' '}

        <UserInfo user={user}/>{' '}

        <small>
          <FormattedDatetime value={timestamp}/>
        </small>
      </ListGroupItem>
    </LinkContainer>
  )
}) |> withRouter;
