import { FIELD } from '../../../../formGroups';

export default ({
  [String(FIELD.ELEMENTS)]: elements,
  [String(FIELD.LIST)]: list,
  [String(FIELD.POINTS)]: points,
  [String(FIELD.POINTS_TYPE)]: pointsType,
  [String(FIELD.SHOW_POINTS)]: showPoints,
  [String(FIELD.POINT_TRESHOLDS)]: pointTresholds,
  [String(FIELD.NOTES)]: notes,
  [String(FIELD.TITLE)]: title,
  ...rest
}) => ({
  ...rest,
  elements,
  list,
  points,
  pointsType,
  showPoints,
  pointTresholds,
  notes,
  title
});
