export const assigned = '../../src/components/Pages/Home/Professional/List/intl:assigned';
export const dateOfExpiry = '../../src/components/Pages/Home/Professional/List/intl:dateOfExpiry';
export const filled = '../../src/components/Pages/Home/Professional/List/intl:filled';
export const status = '../../src/components/Pages/Home/Professional/List/intl:status';
export const form = '../../src/components/Pages/Home/Professional/List/intl:form';
export const additionalInfo = '../../src/components/Pages/Home/Professional/List/intl:additionalInfo';
export const fill = '../../src/components/Pages/Home/Professional/List/intl:fill';
export const show = '../../src/components/Pages/Home/Professional/List/intl:show';
export const actions = '../../src/components/Pages/Home/Professional/List/intl:actions';
export const remove = '../../src/components/Pages/Home/Professional/List/intl:remove';
export const disableModalTitle = '../../src/components/Pages/Home/Professional/List/intl:disableModalTitle';
export const disableModalBody = '../../src/components/Pages/Home/Professional/List/intl:disableModalBody';
export default {assigned, dateOfExpiry, filled, status, form, additionalInfo, fill, show, actions, remove, disableModalTitle, disableModalBody};
