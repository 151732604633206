import handleChildrenFunc from './handleChildrenFunc';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import Empty from '../../Empty';

export default Form => ({
  submitButtonMessageId,
  children = <Empty />,
  ...otherProps
}) => (
  <Form {...otherProps}>
    {handleChildrenFunc(children, children => (
      <>
        {children}
        {submitButtonMessageId && (
          <Button bsStyle="danger" type="submit">
            <FormattedMessage id={submitButtonMessageId} />
          </Button>
        )}
      </>
    ))}
  </Form>
);
