import React, { Component, createRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Alert,
  Col,
  ListGroup,
  ListGroupItem,
  ProgressBar,
  Row
} from 'react-bootstrap';
import { pickBy } from 'lodash';
import PageTitle from './PageTitle';
import PagerDefault from './PagerDefault';
import PagerWithDisabledButtons from './PagerWithDisabledButtons';
import messages from './intl/messages.properties';
import { withRouterLocationState } from '../../../RouterLocationState';
import {SUBMITTED} from "../../../../constants/formStatus";

const ListGroupItemDisableable = ({
  disabled,
  href,
  onClick,
  ...otherProps
}) => (
  <ListGroupItem
    disabled={disabled}
    href={disabled ? undefined : href}
    onClick={disabled ? undefined : onClick}
    {...otherProps}
  />
);

export default Form =>
  class Pagination extends Component {
    form = createRef();

    state = do {
      const page = this.props.state?.page ?? 0;
      ({
        jumpPageMax: page,
        page
      });
    };

    handleSubmit = event => {
      const { onSubmitGoToPage } = this;
      const { formData } = event;
      if (onSubmitGoToPage === undefined) {
        if (this.isLastPage()) {
          this.props.onSubmit({
            ...event,
            formData: { ...this.state.formData, ...formData }
          });
        } else {
          this.handlePageChange({ formData, page: this.state.page + 1 });
        }
      } else {
        this.onSubmitGoToPage = undefined;
        this.handlePageChange({ formData, page: onSubmitGoToPage });
      }
    };

    openPage = index => event => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      event.preventDefault();
      this.onSubmitGoToPage = index;
      this.form.current.onSubmit(event);
    };

    handlePageChange = ({ formData, page }) => {
      this.setState(state => ({
        formData: { ...state.formData, ...formData },
        page,
        jumpPageMax: Math.max(state.jumpPageMax, page)
      }));
      this.props.setState({ page });
      if (this.props.status !== SUBMITTED) {
        this.props.onSaveDraft?.({ formData });
      }
    }

    isLastPage = () => {
      const {
        props: { pages },
        state: { page }
      } = this;
      return page === pages.length - 1;
    }

    render() {
      const {
        props: { submitButtonMessageId, ...props },
        props: {
          pages,
          status,
          schema: { title, ...schema },
          uiSchema,
          isFetching
        },
        state: { jumpPageMax, page },
        form,
        handleSubmit,
        openPage,
        isLastPage
      } = this;

      const { elements } = Object.entries(uiSchema)
        .filter(([key]) => key in schema.properties)
        .reduce(
          ({ current, elements }, [key, { 'ui:field': uiField }]) => {
            if (uiField === PageTitle) {
              return { current: current + 1, elements };
            }
            if (current === -1 || current === page) {
              elements.push(key);
            }
            return { current, elements };
          },
          { current: -1, elements: [] }
        );

      const properties = pickBy(schema.properties, (value, key) =>
        elements.includes(key)
      );
      return (
        <>
          <Row>
            <Col xs={12} sm={12} md={9} mdOffset={3}>
              <h1 id="root_title">{title}</h1>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={3}>
              <ProgressBar now={(page / pages.length) * 100} />

              <ListGroup>
                {pages
                  .map(({ 'ui:title': title }) => title)
                  .map((title, index) => `${index + 1}. ${title}`)
                  .map((title, index) => (
                    <ListGroupItemDisableable
                      key={title}
                      bsStyle={index === page ? 'info' : undefined}
                      href="#"
                      onClick={openPage(index)}
                      disabled={jumpPageMax < index}
                    >
                      {title}
                    </ListGroupItemDisableable>
                  ))}
              </ListGroup>
            </Col>

            <Col xs={12} sm={12} md={9}>
              {isFetching && (
                <Alert bsStyle="warning">
                  <FormattedMessage id={messages.loading} />
                </Alert>
              )}

              <h2 className="form-page_title">{pages[page]['ui:title']}</h2>

              <Form
                key={page}
                formRef={form}
                {...props}
                onSubmit={handleSubmit}
                schema={{
                  ...schema,
                  properties,
                  required: schema.required?.filter(key => key in properties)
                }}
              >
                {isFetching
                  ? (
                    <PagerWithDisabledButtons
                      page={page}
                      openPage={openPage}
                      isLastPage={isLastPage}
                      status={status}
                      submitButtonMessageId={submitButtonMessageId}
                    />
                  ) : (
                    <PagerDefault
                      page={page}
                      openPage={openPage}
                      isLastPage={isLastPage}
                      status={status}
                      submitButtonMessageId={submitButtonMessageId}
                    />
                  )
                }
              </Form>
            </Col>
          </Row>
        </>
      );
    }
  } |> withRouterLocationState;
