import { FIELD } from '../../../../fields';

export default ({
  list,
  type,
  name,
  description,
  help,
  notes,
  selectWidget,
  multiselectWidget,
  points,
  choices,
  choicesPoints,
  labels,
  pattern,
  patternDescription,
  ...rest
}) => ({
  ...rest,
  [FIELD.LIST]: list,
  [FIELD.TYPE]: type,
  [FIELD.NAME]: name,
  [FIELD.DESCRIPTION]: description,
  [FIELD.HELP]: help,
  [FIELD.NOTES]: notes,
  [FIELD.SELECT_WIDGET]: selectWidget,
  [FIELD.MULTISELECT_WIDGET]: multiselectWidget,
  [FIELD.POINTS]: points,
  [FIELD.CHOICES]: choices,
  [FIELD.CHOICES_POINTS]: choicesPoints,
  [FIELD.LABELS]: labels,
  [FIELD.PATTERN]: pattern,
  [FIELD.PATTERN_DESCRIPTION]: patternDescription
});
