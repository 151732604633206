import FormULA from '@visma/form-ula';
import * as ELEMENT from '@visma/form-ula/es/constants/elementTypes';
import FIELD from '@visma/form-ula/es/constants/fieldIDs';
import elements, { convertFormData } from '../../../fields';
import Editor from '../../Editor';
import { CustomFieldsSchema, CustomFieldsUISchema } from "../../CustomFields";


export default props => (
  <Editor
    {...props}
    design={{
      [FIELD.TITLE]: { fi: 'Kysymys' },
      [FIELD.ELEMENTS]: elements
    }}
    Preview={({ formData, formData: { 0: type } = {} }) => (
      <FormULA
        design={{
          [FIELD.ELEMENTS]:
            type === undefined
              ? []
              : [
                  {
                    type: ELEMENT.FIELD,
                    id: 0
                  }
                ]
        }}
        fields={
          type === undefined
            ? undefined
            : [{ ...convertFormData(formData), _id: 0 }]
        }
        extraFieldTypes={{
          schema : {...CustomFieldsSchema},
          uiSchema: () => ({...CustomFieldsUISchema})
        }}
      />
    )}
  />
);
