import { Label, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Dotdotdot from 'react-dotdotdot';
import { FIELD } from '../../forms';
import { FIELD as FG_FIELD } from '../../formGroups'
import connect from '../../utils/react-refetch/connectAndRenderOnFulfilled';
import withLocalize from '../utils/withLocalize';
import messages from '../intl/messages.properties';

export default connect(() => ({
}))(
  (({ element: { _id }, forms, formgroups, localize }) => do {
    const { count, userForms } = forms.reduce(
      (usage, form) =>
        form[FIELD.ELEMENTS].some(({ id }) => id === _id)
          ? {
              count: usage.count + 1,
              userForms: [...usage.userForms, form]
            }
          : usage,
      { count: 0, userForms: [] }
    );
    const {fgCount, userFormgroups} = formgroups ? formgroups.reduce(
      (usage, formgroup) =>
        formgroup[FG_FIELD.ELEMENTS]?.some(({ id }) => id === _id)
          ? {
            fgCount: usage.fgCount + 1,
          userFormgroups: [...usage.userFormgroups, formgroup]
          } : usage,
      { fgCount: 0, userFormgroups: [] }
    ) : { fgCount: null, userFormgroups: [] };
    const label = (
      <Label bsStyle="success" style={{ fontSize: 10.5 }}>
        <FormattedMessage id={messages.formUsageCount} values={{ count }} />
      </Label>
    );

    const fgLabel = (
      <Label bsStyle="success" style={{ fontSize: 10.5 }}>
        <FormattedMessage id={messages.formgroupUsageCount} values={{ count: fgCount }} />
      </Label>
    );
    (
      <>
        {count ? (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="tooltip">
                <Dotdotdot clamp={4}>
                  {userForms
                    .map(({ [FIELD.TITLE]: title, _id }) => localize(title))
                    .join(', ')}
                </Dotdotdot>
              </Tooltip>
            }
          >
            {label}
          </OverlayTrigger>
        ) : (
          label
        )}
        {' '}
        { formgroups ? fgCount ? (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="tooltip">
              <Dotdotdot clamp={4}>
                {userFormgroups
                  .map(({ [FG_FIELD.TITLE]: title, _id }) => localize(title))
                  .join(', ')}
              </Dotdotdot>
            </Tooltip>
          }
        >
          {fgLabel}
        </OverlayTrigger>
        ) : (
        fgLabel
        ) : null}
      </>
    )

  }) |> withLocalize
);
