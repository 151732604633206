import { FormattedMessage } from 'react-intl';
import {
  ControlLabel,
  FormGroup,
  ToggleButton,
  ToggleButtonGroup
} from 'react-bootstrap';
import { Consumer } from './context';
import messages from './intl/messages.properties';

const locales = { fi: messages.fi, en: messages.en };

export default Widget => ({
  formData,
  idSchema: { $id },
  onChange,
  schema,
  uiSchema: { 'ui:title': title = schema.title }
}) => (
  <Consumer>
    {({ value: language, onChange: handleLanguageChange }) => (
      <FormGroup controlId={`${$id}_value`}>
        {title ? (
          <>
            <ControlLabel>{title}</ControlLabel>{' '}
          </>
        ) : (
          // Zero width space inside <ControlLabel>.
          <ControlLabel>​</ControlLabel>
        )}
        <ToggleButtonGroup
          type="radio"
          name={`${$id}_language`}
          value={language}
          onChange={handleLanguageChange}
          bsSize="xsmall"
        >
          {Object.entries(locales).map(locale => (
            <ToggleButton
              key={locale[0]}
              value={locale[0]}
              bsStyle={language === locale[0] ? 'info' : undefined}
            >
              <FormattedMessage id={locale[1]} />
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <Widget
          value={formData?.[language] || ''}
          onChange={value => {
            onChange({ ...formData, [language]: value });
          }}
        />
      </FormGroup>
    )}
  </Consumer>
);
