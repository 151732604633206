import { injectIntl } from 'react-intl';
import { FormGroup, ListGroup } from 'react-bootstrap';
import { FIELD } from '../../../fields';
import connect from '../../../utils/react-refetch/connectAndRenderOnFulfilled';
import ButtonNew from '../../ButtonNew';
import Filter from '../../Filter';
import ListGroupItem from '../../lists/ListGroupItem';
import Header from '../../lists/ListGroupItem/Header';
import { getLanguage } from '../../utils/withLocalize';
import UsageCount from '../../lists/UsageCount';
import messages from './intl/messages.properties';

export default connect(() => ({
  fieldsFetch: '/api/field',
  formsFetch: '/api/form',
  formgroupsFetch: '/api/formgroup'
}))(
  (({ fieldsFetch, formsFetch, formgroupsFetch, intl: { locale } }) => (
    <>
      <Filter items={fieldsFetch} path={[FIELD.NAME, getLanguage(locale)]}>
        {({ items, searchWords }) => (
          <ListGroup>
            {items.map(item => (
              <ListGroupItem
                key={item._id}
                item={item}
                header={
                  <>
                    <Header name={item[FIELD.NAME]} searchWords={searchWords} />{' '}
                    <UsageCount element={item} forms={formsFetch} formgroups={formgroupsFetch}/>
                  </>
                }
              >
                {item[FIELD.NOTES]}
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
      </Filter>
      <FormGroup>
        <ButtonNew id={messages.newField} />
      </FormGroup>
    </>
  )) |> injectIntl
);
