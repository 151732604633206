import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import FormULA from '@visma/form-ula';
import FIELD from '@visma/form-ula/es/constants/fieldIDs';
import * as ELEMENT from '@visma/form-ula/es/constants/elementTypes';
import * as TYPE from '@visma/form-ula/es/constants/fieldTypes';
import messages from "./intl/messages.properties";
import common from '../../../../intl/messages.properties';
import connect from "../../../../../utils/react-refetch/connectAndRenderOnFulfilled";
import withLocalize from "../../../../utils/withLocalize";


export default connect(({onDisable, disableFormId}) => ({
  disable: ({0: info}) => ({
    disableResponse: {
      url: `/api/assigned/${disableFormId}`,
      method: 'DELETE',
      data: info.replace(/^"|"$/g, ''),
      then: (value) => do {
        onDisable();
        ({value});
      }
    }
  })
}))((({disable, disableFormId, reasonModalOpen, setReasonModalOpen}) => (
  <>
    <Modal show={reasonModalOpen} onHide={() => setReasonModalOpen(false)}>
      <FormULA
        design={{
          [FIELD.ELEMENTS]: [
            {
              type: ELEMENT.FIELD,
              id: 0,
              required: true
            }
          ]
        }}
        fields={[
          {
            [FIELD.TYPE]: TYPE.TEXTAREA,
            [FIELD.NAME]: {
              fi: 'Kohdistuksen poiston syy'
            },
            _id: 0
          }
        ]}
        onSubmit={({formData}) => {
          disable(formData);
          setReasonModalOpen(false);
        }}
      >
        {form => (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id={messages.disableModalTitle}/>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{form}</Modal.Body>
            <Modal.Footer>
              <Button type="submit" bsStyle="success">
                <FormattedMessage id={messages.remove}/>
              </Button>
              <Button onClick={() => setReasonModalOpen(false)}>
                <FormattedMessage id={common.cancel}/>
              </Button>
            </Modal.Footer>
          </>
        )}
      </FormULA>
    </Modal>
  </>
)) |> withLocalize
);
