import { FIELD } from '../../../../forms';

export default ({ elements, notes, assignable, successText, title, showLetters, ...rest }) => ({
  ...rest,
  [FIELD.ELEMENTS]: elements,
  [FIELD.NOTES]: notes,
  [FIELD.ASSIGNABLE]: assignable,
  [FIELD.SUCCESS_TEXT]: successText,
  [FIELD.TITLE]: title,
  [FIELD.SHOW_LETTERS]: showLetters
});
