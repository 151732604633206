export default Form =>
  class TrimmedString extends Form {
    trimAllStrings = formData => {
      let trimmedData;

      if (Array.isArray(formData)) {
        trimmedData = []
      } else {
        trimmedData = {}
      }

      for (const [key, value] of Object.entries(formData)) {
        let trimmedValue = ""

        if (value && (typeof value === 'string')) {
          trimmedValue = value.trim()
        }

        if (value && (typeof value === 'object')) {
          trimmedValue = this.trimAllStrings(value)
        }

        trimmedData[key] = trimmedValue || value
      }

      return trimmedData
    }

    validate = formData => {
      const trimmedFormData = this.trimAllStrings(formData);

      return super.validate(trimmedFormData);
    }
  };
