import React from 'react';
import { ButtonToolbar, FormGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Provider } from "@visma/form-ula/es/utils/react-jsonschema-form/Form/FormData";
import connect from '../../../../utils/react-refetch/connectAndRenderOnFulfilled';
import ButtonPrint from '../../../ButtonPrint';
import LocalStorage from '../../../LocalStorage';
import messages from '../../../intl/messages.properties';
import NoPrint from '../../../NoPrint';
import FormName from '../FormName';
import Form from './Form';

export const defaultFormat = 'condensed';

export default connect(({ match: { params: { id } } }) => ({
  dataFetch: `/api/formdata/${id}`,
  formFetch: `/api/form`,
  assignedForms: '/api/assigned'
}))(({ dataFetch, formFetch, assignedForms }) => (
  <LocalStorage itemKey={module.id}>
    {({ value = defaultFormat }) => {
      const formName = <FormName form={formFetch.find(f => f._id === dataFetch.form.id)} />
      const assignedForm = assignedForms.find(f => f.formId === dataFetch.form.id && f.formRev === dataFetch.form.rev)
      const revTitle = assignedForm?.hasOwnProperty('revTitle') ? assignedForm.revTitle : null

      return (
        <div className='valuesView'>
          <Provider value={value}>
            <FormGroup>
              <NoPrint>
                <ButtonToolbar className="pull-right">
                  <ButtonPrint />
                </ButtonToolbar>
              </NoPrint>
            </FormGroup>

            <h1>
              <FormattedMessage id={messages.values} />: {formName} {revTitle ? `(${revTitle})` : null}
            </h1>

            <Form data={dataFetch} />

            <FormGroup>
              <NoPrint>
                <ButtonToolbar className="pull-right">
                  <ButtonPrint />
                </ButtonToolbar>
              </NoPrint>
            </FormGroup>

            <FormGroup>​{/* Zero width space. */}</FormGroup>
          </Provider>
        </div>
      )
    }}
  </LocalStorage>
));
