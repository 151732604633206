import PageTitle from './PageTitle';
import withPagination from './withPagination';

export default Form => do {
  const Pagination = withPagination(Form);

  props => {
    const pages = Object.values(props.uiSchema).filter(
      ({ 'ui:field': uiField }) => uiField === PageTitle
    );

    return pages.length ? (
      <Pagination {...props} pages={pages} />
    ) : (
      <Form {...props} />
    );
  };
};
