import React from 'react';
import { Button, Pager } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from './intl/messages.properties';
import { SUBMITTED } from '../../../../constants/formStatus';

const PagerDefault = ({
  page,
  openPage,
  isLastPage,
  status,
  submitButtonMessageId
}) => (
  <Pager>
    {page !== 0 && (
      <Pager.Item
        previous
        href="#"
        onClick={openPage(page - 1)}
      >
        ← <FormattedMessage id={messages.previous} />
      </Pager.Item>
    )}

    {!isLastPage() ? (
      <Pager.Item
        next
        href="#"
        onClick={openPage(page + 1)}
      >
        <FormattedMessage id={messages.next} /> →
      </Pager.Item>
    ) : status !== SUBMITTED && (
      <Button
        bsStyle="primary"
        type="submit"
        className="pull-right"
      >
        <FormattedMessage
          id={
            isLastPage()
              ? submitButtonMessageId
              : messages.next
          }
        />
      </Button>
    )}
  </Pager>
);

export default PagerDefault;
