/* eslint react/no-unused-state: "off" */
import { Component } from 'react';

export default class FormData extends Component {
  state = {};

  onChange = ({ formData }) => {
    this.setState({ formData, isInnerChange: true });
  };

  static getDerivedStateFromProps(props, { formData, isInnerChange }) {
    return {
      formData: isInnerChange ? formData : props.formData,
      isInnerChange: false
    };
  }

  render() {
    const {
      props: { children, onChange = this.onChange, ...otherProps },
      state: { formData }
    } = this;
    return children({
      ...otherProps,
      onChange,
      formData
    });
  }
}
