import { identity } from 'lodash';
import Empty from '../../Empty';

/*
Example:
<Modal>
  <Form
    onSubmit={this.handleSubmit}
    {...otherProps}
  >
    {form => (
      <>
        <Modal.Body>{form}</Modal.Body>
        <Modal.Footer>
          <Button type="submit" bsStyle="primary">
            Submit
          </Button>
        </Modal.Footer>
      </>
    )}
  </Form>
</Modal>
*/

export default Form =>
  class RenderProp extends Form {
    extendRenderProp(wrapper = identity) {
      const {
        props,
        props: { children }
      } = this;
      const isFunction = typeof children === 'function';
      const ctx = isFunction
        ? Object.create(this, {
            props: { value: { ...props, children: <Empty /> } }
          })
        : this;
      const element = ctx::super.render();
      return isFunction
        ? (function getFormElement(element) {
            return {
              ...element,
              props: {
                ...element.props,
                children:
                  element.type === 'form'
                    ? // Replace children with a result of render prop call that
                      // gets original children as a parameter, optionally
                      // modified by a wrapper function.
                      children(wrapper(element.props.children))
                    : // Recursively find form element.
                      getFormElement(element.props.children)
              }
            };
          })(element)
        : wrapper(element);
    }

    render() {
      return this.extendRenderProp();
    }
  };
