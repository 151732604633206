/* eslint react/jsx-wrap-multilines: "off" */
import { FormGroup } from 'react-bootstrap';
import FormULA from '@visma/form-ula';
import { localizedText as localizedTextSchema } from '@visma/form-ula/es/conversions/elementToSchema';
import { localizedText as localizedTextUISchema } from '@visma/form-ula/es/conversions/elementToUISchema';
import GetFormData from '@visma/form-ula/es/utils/react-jsonschema-form/GetFormData';
import { last } from 'lodash';
import * as ELEMENT_TYPE from '@visma/form-ula/es/constants/elementTypes';
import FIELD from '@visma/form-ula/es/constants/fieldIDs';
import * as FIELD_TYPE from '@visma/form-ula/es/constants/fieldTypes';
import { FIELD as FORMGROUP_FIELD } from '../../formGroups';
import withLocalize from '../utils/withLocalize';
import fields from './fields';
import Layout from './Layout';
import { save } from '../intl/messages.properties';
import withFields from '../Pages/Fields/withFields';
import withFormGroups from '../Pages/FormGroups/withFormGroups';
import getFilterField from './getFilterField';
import MaintainRuleIndicesArrayField from './MaintainRuleIndicesArrayField';

const noTitle = { 'ui:title': ' ' };

export default (({
  fields: userFields,
  formGroups: userFormGroups,
  Preview,
  localize,
  ...otherProps
}) => {
  const getSelectableItem = (data, title) => ({
    type: 'integer',
    enum: data.map(({ _id }) => _id),
    enumNames: data.map(field => field[title]).map(localize)
  });
  return (
    <GetFormData {...otherProps}>
      {props => {
        const { formData } = props;
        return (
          <Layout
            left={
              <FormGroup>
                <FormULA
                  {...props}
                  fields={fields}
                  submitButtonMessageId={save}
                  extraFieldTypes={{
                    schema: {
                      [FIELD_TYPE.SELECT_ELEMENT]: {
                        title: localize({ fi: 'Tyyppi' }),
                        oneOf: [
                          ...(userFields.length
                            ? [
                                {
                                  title: localize({ fi: 'Kysymys' }),
                                  type: 'object',
                                  properties: {
                                    id: getSelectableItem(
                                      userFields,
                                      FIELD.NAME
                                    ),
                                    type: {
                                      type: 'string',
                                      enum: [ELEMENT_TYPE.FIELD]
                                    },
                                    required: {
                                      type: 'boolean',
                                      title: localize({
                                        fi: 'Kysymys on pakollinen'
                                      })
                                    },
                                    prefill: {
                                      type: 'boolean',
                                      title: localize({ fi: 'Esitäyttö' })
                                    },
                                    filter: {
                                      type: 'object',
                                      properties: {}
                                    }
                                  },
                                  default: { type: ELEMENT_TYPE.FIELD }
                                }
                              ]
                            : []),
                          ...(userFormGroups.length
                            ? [
                                {
                                  title: localize({ fi: 'Kysymysryhmä' }),
                                  type: 'object',
                                  properties: {
                                    id: getSelectableItem(
                                      userFormGroups,
                                      FORMGROUP_FIELD.TITLE
                                    ),
                                    type: {
                                      type: 'string',
                                      enum: [ELEMENT_TYPE.FORMGROUP]
                                    },
                                    filter: {
                                      type: 'object',
                                      properties: {}
                                    }
                                  },
                                  default: { type: ELEMENT_TYPE.FORMGROUP }
                                }
                              ]
                            : []),
                          {
                            title: localize({ fi: 'Sivuerotin ja otsikko' }),
                            type: 'object',
                            properties: {
                              type: {
                                type: 'string',
                                enum: [ELEMENT_TYPE.PAGE_TITLE]
                              },
                              content: localizedTextSchema
                            },
                            default: { type: ELEMENT_TYPE.PAGE_TITLE }
                          },
                          {
                            title: localize({ fi: 'Otsikko' }),
                            type: 'object',
                            properties: {
                              type: {
                                type: 'string',
                                enum: [ELEMENT_TYPE.TITLE]
                              },
                              content: localizedTextSchema
                            },
                            default: { type: ELEMENT_TYPE.TITLE }
                          },
                          {
                            title: localize({ fi: 'Leipäteksti' }),
                            type: 'object',
                            properties: {
                              type: {
                                type: 'string',
                                enum: [ELEMENT_TYPE.BODY]
                              },
                              content: localizedTextSchema,
                              filter: {
                                type: 'object',
                                properties: {}
                              }
                            },
                            default: { type: ELEMENT_TYPE.BODY }
                          },
                          {
                            title: localize({ fi: 'Leipäteksti (Ammattilainen)' }),
                            type: 'object',
                            properties: {
                              type: {
                                type: 'string',
                                enum: [ELEMENT_TYPE.BODY_PROF]
                              },
                              content: localizedTextSchema,
                              filter: {
                                type: 'object',
                                properties: {}
                              }
                            },
                            default: { type: ELEMENT_TYPE.BODY_PROF }
                          }
                        ]
                      }
                    },
                    uiSchema: ({ formData: elements }) => ({
                      [FIELD_TYPE.SELECT_ELEMENT]: {
                        'ui:field': MaintainRuleIndicesArrayField,
                        'ui:should-update': JSON.stringify(elements),
                        items: ({
                          formData: { type } = {},
                          idSchema: { $id }
                        }) => ({
                          type: { 'ui:widget': 'hidden' },
                          ...{
                            [ELEMENT_TYPE.BODY]: {
                              content: {
                                ...noTitle,
                                'ui:field': 'TranslatableRichText'
                              },
                              filter: getFilterField({
                                fields: userFields,
                                formGroups: userFormGroups,
                                elements,
                                index: last($id.split('_'))
                              })
                            },
                            [ELEMENT_TYPE.BODY_PROF]: {
                              content: {
                                ...noTitle,
                                'ui:field': 'TranslatableRichText'
                              },
                              filter: getFilterField({
                                fields: userFields,
                                formGroups: userFormGroups,
                                elements,
                                index: last($id.split('_'))
                              })
                            },
                            [ELEMENT_TYPE.FIELD]: {
                              id: noTitle,
                              filter: getFilterField({
                                fields: userFields,
                                formGroups: userFormGroups,
                                elements,
                                index: last($id.split('_'))
                              })
                            },
                            [ELEMENT_TYPE.FORMGROUP]: {
                              id: noTitle,
                              filter: getFilterField({
                                fields: userFields,
                                formGroups: userFormGroups,
                                elements,
                                index: last($id.split('_'))
                              })
                            },
                            [ELEMENT_TYPE.PAGE_TITLE]: {
                              content: {
                                ...localizedTextUISchema,
                                ...noTitle
                              }
                            },
                            [ELEMENT_TYPE.TITLE]: {
                              content: {
                                ...localizedTextUISchema,
                                ...noTitle
                              }
                            }
                          }[type]
                        })
                      }
                    })
                  }}
                />
              </FormGroup>
            }
            right={
              <FormGroup>
                <Preview formData={formData} />
              </FormGroup>
            }
          />
        );
      }}
    </GetFormData>
  );
})
  |> withFields
  |> withFormGroups
  |> withLocalize;
