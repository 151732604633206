import { FormGroup } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { Role, PROFESSIONAL } from '../../Role';
import PatientInfo from '../../PatientInfo';
import { withToken } from '../../App/Authorize';
import View from '../../../api/View';

export default (({
  intl: { locale },
  match: {
    params: { id, dataId }
  },
  token
}) => (
  <>
    <Role is={PROFESSIONAL}>
      <PatientInfo />
    </Role>
    <FormGroup>
      <View
        authToken={token}
        dataId={dataId}
        id={id}
        locale={locale}
        onSubmitRedirectTo="/values"
      />
    </FormGroup>
  </>
))
  |> withToken
  |> injectIntl;
