import { enable } from '../filter';
import elementToUISchema from './elementToUISchema';

export default props => ({ elements, formData }) => ({
  ...elements.map(elementToUISchema(props)).map(
    (uiSchema, index) => do {
      const disabled = !enable({ elements, formData })(elements[index]);
      typeof uiSchema === 'function'
        ? (...args) => ({
            ...uiSchema(...args),
            'ui:disabled': disabled
          })
        : {
            ...uiSchema,
            'ui:disabled': disabled
          };
    }
  )
});
