import { Component } from 'react';
import { FormGroup, FormControl, Col, Button, Row } from 'react-bootstrap';

export default class Slider extends Component {
  static defaultProps = {
    value: 0,
    options: {
      enumOptions: []
    }
  };

  state = {};

  static getDerivedStateFromProps = ({value, options: { enumOptions }}, state) => {
    return {
      value: state?.value || value || 0,
      minValue: 0,
      maxValue: Math.max(enumOptions.length-1, 0),
      options: enumOptions
    }
  }

  AddValue = () => {
    const newState = {...this.state};
    newState.value = (newState.value+1) <= this.state.maxValue ? newState.value+1 : newState.value;
    this.setState(newState);
    this.props.onChange(newState.value);
  }

  SubtractValue = () => {
    const newState = {...this.state};
    newState.value = (newState.value-1) >= this.state.minValue ? newState.value-1 : newState.value;
    this.setState(newState);
    this.props.onChange(newState.value);
  }

  ProcessValue = (event) => {
    const newState = {...this.state};
    newState.value = Math.max(Math.min(event.target.value, this.state.maxValue),this.state.minValue);
    this.setState(newState);
    this.props.onChange(newState.value);
  }

  render() {
    return (
      <FormGroup className="slider">
        <Row>
          <Col xs={1}></Col>
          <Col xs={3}>
            {this.state.options[0]?.label}
          </Col>
          <Col xs={4} style={{textAlign: 'center'}}>
            {this.state.options[this.state.value]?.label}
          </Col>
          <Col xs={3} style={{textAlign: 'right'}}>
            {this.state.options[this.state.options.length-1]?.label}
          </Col>
        </Row>
        <Row>
          <Col xs={1}>
            <Button onClick={() => this.SubtractValue()}>-</Button>
          </Col>
          <Col xs={10} aria-live="polite">
            <FormControl
              type="range"
              min={this.state.minValue}
              max={this.state.maxValue}
              value={this.state.value}
              onChange={event => this.ProcessValue(event)}
              aria-label={`${this.state.options[this.state.value]?.label}`}
            />
          </Col>
          <Col xs={1}>
            <Button onClick={() => this.AddValue()}>+</Button>
          </Col>
        </Row>
      </FormGroup>
    );
  }
}
