import React from 'react';
import { FIELD } from '../../../../../forms';
import { FIELD as FORMGROUP_FIELD } from '../../../../../formGroups';
import {FIELD as FIELDS_FIELD, FIELD as FIELD_FIELD} from '../../../../../fields';
import {BMI, LETTER, NUMBER} from '@visma/form-ula/es/constants/pointTypes';

import connect from '../../../../../utils/react-refetch/connectAndRenderOnFulfilled';
import { Fields } from './Fields';
import {Label} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import messages from "../../../../intl/messages.properties";

export default connect(({ data: { form: { id, rev } } }) => ({
  formFetch: `/api/form/${id}/${rev}`,
  formgroupFetch: `/api/formgroup/forForm/${id}/${rev}`,
  fieldFetch: `/api/field/forForm/${id}/${rev}`
}))(({ data: { values }, formFetch, formgroupFetch, fieldFetch }) => {
  let sum = [];
  let index = 0;

  formFetch[FIELD.ELEMENTS].forEach(e => {
    if (e.type === "formgroup") {
      const fg = formgroupFetch.find(fg => fg._id === e.id);

      // Not a points formgroup
      if (!fg[FORMGROUP_FIELD.POINTS]) {
        index++;
        return;
      }

      // No value
      if (!values[index]) {
        index++;
        return;
      }

      // NUMBER-typed formgroups have a numeral sum and may have letter tresholds based on numeral points
      if (fg[FORMGROUP_FIELD.POINTS_TYPE] === NUMBER && fg[FORMGROUP_FIELD.POINT_TRESHOLDS]) {
        let fgIndex = 0;
        let fgSum = 0;
        fg[FORMGROUP_FIELD.ELEMENTS].forEach(fgEl => {
          if (fgEl.type !== "field") {
            fgIndex++;
            return null;
          }
          const field = fieldFetch.find(f => f._id === fgEl.id);
          const fieldVal = values[index][fgIndex];
          if (field[FIELD_FIELD.POINTS] && fieldVal) {
            const fieldPoints = field[FIELD_FIELD.CHOICES_POINTS];
            if (Array.isArray(fieldVal)) {
              fieldVal.forEach(value => {
                const answerPoints = parseInt(fieldPoints?.[parseInt(value)]);
                if (answerPoints > 0) {
                  fgSum = fgSum + answerPoints;
                }
              });
            } else {
              const answerPoints = parseInt(fieldPoints?.[parseInt(fieldVal)]);
              if (answerPoints > 0) {
                fgSum = fgSum + answerPoints;
              }
            }
          }
          fgIndex++;
        });
        // Fetch letters from tresholds
        const tresholds = fg[FORMGROUP_FIELD.POINT_TRESHOLDS]
          .filter(pt => pt)
          .map(pt => pt.split(";"))
          .filter(pt => !!parseInt(pt[0]) || parseInt(pt[0]) === 0)
          .sort((pt1, pt2) => parseInt(pt1[0]) > parseInt(pt2[0]) ? 0 : 1);
        if (tresholds.length < 1) {
          index++;
          return;
        }
        for (let i = 0; i < tresholds.length; i++) {
          if (parseInt(tresholds[i][0]) <= fgSum) {
            const tresholdVal = tresholds[i][1].split("|");
            if (tresholdVal.length > 1) {
              tresholdVal[1].split('').forEach(letter => {
                if (sum.indexOf(letter) === -1) {
                  sum.push(letter);
                }
              });
            }
            index++;
            return;
          }
        }

        // LETTER-typed formgroups simply sum up letters and add to overall sum
      } else if (fg[FORMGROUP_FIELD.POINTS_TYPE] === LETTER) {
        let fgIndex = 0;
        fg[FORMGROUP_FIELD.ELEMENTS].forEach(fgEl => {
          if (fgEl.type !== "field") {
            fgIndex++;
            return null;
          }
          const field = fieldFetch.find(f => f._id === fgEl.id);
          if (field[FIELD_FIELD.POINTS] && values[index]) {
            if (Array.isArray(values[index][fgIndex])) {
              values[index][fgIndex].forEach(val => {
                const answerLetters = field[FIELDS_FIELD.CHOICES_POINTS][val];
                answerLetters?.split('').forEach(letter => {
                  if (sum.indexOf(letter) === -1) {
                    debugger;
                    sum.push(letter);
                  }
                });
              });
            } else {

              const fieldPoints = field[FIELDS_FIELD.CHOICES_POINTS];
              const answerLetters = fieldPoints?.[values[index][fgIndex]];
              answerLetters?.split('').forEach(letter => {
                if (sum.indexOf(letter) === -1) {
                  sum.push(letter);
                }
              });
            }
          }
          fgIndex++;
        })
      // BMI-typed formgroups may have tresholds similar to NUMBER-types, difference is the points is calculated with a BMI formula
      } else if (fg[FORMGROUP_FIELD.POINTS_TYPE] === BMI) {
        const fgValues = values[index];
        const tresholds = fg[FORMGROUP_FIELD.POINT_TRESHOLDS] ? fg[FORMGROUP_FIELD.POINT_TRESHOLDS][0].split(";") : null;
        if (fgValues.length < 2 || fgValues[0] === undefined || fgValues[1] === undefined) {
          index++;
          return;
        }

        const length = parseFloat(fgValues[0]) / 100;
        const weight = parseFloat(fgValues[1]);

        // BMI formula
        const bmi = weight / length / length;

        if (!tresholds || tresholds.length < 1) {
          index++;
          return;
        }

        // Fetch letters from tresholds
        if (tresholds && (parseInt(tresholds[0]) <= bmi)) {
          tresholds[1].split('').forEach(letter => {
            if (sum.indexOf(letter) === -1) {
              sum.push(letter);
            }
          })
        }
      }
    }
    index++;
  });
  return (
    <>
      {formFetch[FIELD.SHOW_LETTERS] && (
        <Label bsStyle="warning">
          <FormattedMessage id={messages.labStudies} values={{ sum: sum.sort().join("") }} />
        </Label>
      )}

      <Fields elements={formFetch[FIELD.ELEMENTS]} values={values} fields={fieldFetch} formGroups={formgroupFetch} />
    </>
  );
});
