import { FormGroup } from 'react-bootstrap';
import { parse } from 'qs';
import { Consumer as FormData } from './FormData';

export default Form =>
  class Debug extends Form {
    render() {
      return (
        <FormData>
          {({formData}) => do {
            const renderer = JSON.parse(
              parse(global.location.search.slice(1)).debug || 'false'
            )
              ? form => (
                  <>
                    <FormGroup>{form}</FormGroup>
                    <pre>{JSON.stringify(formData, null, 2)}</pre>
                  </>
                )
              : undefined;
            super.extendRenderProp
              ? super.extendRenderProp(renderer)
              : renderer(super.render());
          }}
        </FormData>
      );
    }
  };
