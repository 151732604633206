import indices from '@visma/form-ula/es/constants/indices';
import * as TYPE from '@visma/form-ula/es/constants/elementTypes';
import * as FIELD_TYPE from '@visma/form-ula/es/constants/fieldTypes';
import formDataConverter from './formDataConverter';
import { FIELD as EDITOR_FIELD } from './components/Editor/fields';

const elements = {
  TYPE: { id: EDITOR_FIELD.TYPE, type: TYPE.FIELD },
  NAME: {
    id: EDITOR_FIELD.NAME,
    type: TYPE.FIELD,
    filter: { show: { target: 0, exists: true } }
  },
  DESCRIPTION: {
    id: EDITOR_FIELD.DESCRIPTION,
    type: TYPE.FIELD,
    filter: { show: { target: 0, exists: true } }
  },
  HELP: {
    id: EDITOR_FIELD.HELP,
    type: TYPE.FIELD,
    filter: { show: { target: 0, exists: true } }
  },
  NOTES: {
    id: EDITOR_FIELD.NOTES,
    type: TYPE.FIELD,
    filter: { show: { target: 0, exists: true } }
  },
  LIST: {
    id: EDITOR_FIELD.LIST,
    type: TYPE.FIELD,
    filter: { show: { target: 0, exists: true } }
  },
  SELECT_WIDGET: {
    id: EDITOR_FIELD.SELECT_WIDGET,
    type: TYPE.FIELD,
    filter: { show: { target: 0, equals: FIELD_TYPE.SELECT } }
  },
  MULTISELECT_WIDGET: {
    id: EDITOR_FIELD.MULTISELECT_WIDGET,
    type: TYPE.FIELD,
    filter: { show: { target: 0, equals: FIELD_TYPE.MULTISELECT } }
  },
  CHOICES: {
    id: EDITOR_FIELD.CHOICES,
    type: TYPE.FIELD,
    filter: {
      show: {
        target: 0,
        anyOf: [
          { equals: FIELD_TYPE.SELECT },
          { equals: FIELD_TYPE.MULTISELECT },
          { equals: FIELD_TYPE.SLIDER }
        ]
      }
    }
  },
  POINTS: {
    id: EDITOR_FIELD.POINTS,
    type: TYPE.FIELD,
    filter: {
      show: {
        target: 0,
        anyOf: [
          { equals: FIELD_TYPE.SELECT },
          { equals: FIELD_TYPE.MULTISELECT }
        ]
      }
    }
  },
  CHOICES_POINTS: {
    id: EDITOR_FIELD.CHOICES_POINTS,
    type: TYPE.FIELD,
    filter: {
      show: {
            target: 9,
            equals: true
          }
    }
  },
  LABELS: {
    id: EDITOR_FIELD.LABELS,
    type: TYPE.FIELD,
    filter: {
      show: {
        target: 0,
        equals: FIELD_TYPE.STRING_PAIR
      }
    }
  },
  ...do {
    const filter = {
      show: {
        target: 0,
        equals: FIELD_TYPE.BOOLEAN
      }
    };
    ({
      BOOLEAN_WIDGET: {
        id: EDITOR_FIELD.BOOLEAN_WIDGET,
        type: TYPE.FIELD,
        filter
      },
      YES: { id: EDITOR_FIELD.YES, type: TYPE.FIELD, filter },
      NO: { id: EDITOR_FIELD.NO, type: TYPE.FIELD, filter },
      BOOLEAN_DEFAULT: {
        id: EDITOR_FIELD.BOOLEAN_DEFAULT,
        type: TYPE.FIELD,
        filter
      }
    });
  },
  PATTERN: {
    id: EDITOR_FIELD.PATTERN,
    type: TYPE.FIELD,
    filter: { show: { target: 0, equals: FIELD_TYPE.TEXT } }
  },
  PATTERN_DESCRIPTION: {
    id: EDITOR_FIELD.PATTERN_DESCRIPTION,
    type: TYPE.FIELD,
    filter: { show: { target: 0, equals: FIELD_TYPE.TEXT } }
  }
};

export default Object.values(elements);

export const FIELD = indices(elements);

export const convertFormData = formDataConverter(elements);
