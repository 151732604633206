import Form from 'react-jsonschema-form';
import Debug from './Debug';
import Fields from './Fields';
import LiveValidateOnOnError from './LiveValidateOnOnError';
import LocalizeErrors from './LocalizeErrors';
import LocalizeErrorList from './LocalizeErrorList';
import LocalizeSubmitButton from './LocalizeSubmitButton';
import NoHtml5Validate from './NoHtml5Validate';
import Pagination from './Pagination';
import RenderProp from './RenderProp';
import SchemaAsFunction from './SchemaAsFunction';
import StopPropagationOnSubmit from './StopPropagationOnSubmit';
import FormData from './FormData';
import Widgets from './Widgets';
import Ref from './Ref';
import LocalizeSaveDraftButton from "./LocalizeSaveDraftButton";
import TrimmedString from './TrimmedString';

export default Form
  |> RenderProp
  |> TrimmedString
  |> Debug
  |> FormData
  |> Ref
  |> StopPropagationOnSubmit
  |> SchemaAsFunction
  |> LiveValidateOnOnError
  |> LocalizeErrors
  |> LocalizeErrorList
  |> LocalizeSubmitButton
  |> LocalizeSaveDraftButton
  |> Pagination
  |> Fields
  |> Widgets
  |> NoHtml5Validate;
