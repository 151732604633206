import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FIELD as FORM_ELEMENTS } from '../forms';
import ButtonPrint from './ButtonPrint';
import withLocalize from './utils/withLocalize';
import messages from './intl/messages.properties';
import { Fields } from './Pages/Values/View/Form/Fields';

export default (props => {
    const {
      intl: { formatDate },
      dataFetch,
      formFetch,
      fields,
      formGroups,
      history,
      onCloseRedirectTo,
      values
    } = props;

    return (
      <>
        <h1>
          <FormattedMessage
            id={messages.valuesAdded}
            values={{
              date: formatDate(dataFetch.timestamp, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              })
            }}
          />
        </h1>

        <Fields
          elements={formFetch[FORM_ELEMENTS.ELEMENTS]}
          values={values}
          fields={fields}
          formGroups={formGroups}
        />

        <ButtonToolbar>
          <Button
            bsStyle="primary"
            onClick={() => history.replace(onCloseRedirectTo)}
          >
            <FormattedMessage id={messages.close}/>
          </Button>

          <ButtonPrint/>
        </ButtonToolbar>
      </>
    );
  }
) |> injectIntl|> withLocalize;
