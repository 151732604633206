import { mapValues } from 'lodash';
import mode, { DEVELOPMENT, MOCK, PRODUCTION } from '../../mode';
import Mock from './Mock';
import convertData from './convertData';
import { getHost } from '.';

const withHost = propsToOptions =>
  convertData(
    props =>
      propsToOptions(props)
      |> (options => do {
        const host = getHost();
        mapValues(options, option =>
          ({
            function: () => data =>
              mapValues(option(data), options => ({
                ...mapValues(
                  options,
                  option =>
                    typeof option === 'function'
                      ? request =>
                          mapValues(option(request), (option = {}) => ({
                            ...option,
                            url: `${host}${option.url}`
                          }))
                      : option
                ),
                url: `${host}${options.url}`
              })),
            object: () => ({ ...option, url: `${host}${option.url}` }),
            string: () => `${host}${option}`
          }[typeof option]())
        );
      })
  );

export default {
  [DEVELOPMENT]: withHost,
  [MOCK]: propsToOptions => Component => props => (
    <Mock {...props} propsToOptions={propsToOptions} Component={Component} />
  ),
  [PRODUCTION]: withHost
}[mode];
