import { Alert } from 'react-bootstrap';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import connect from '../utils/react-refetch/connectAndRenderOnFulfilled';
import messages from './intl/messages.properties';

export default connect(() => ({
  patient: '/api/patient'
}))(
  (({ intl: { formatMessage }, patient: { name, nationalId, agreement } }) => (
    <>
    <Alert bsStyle="info">
      <FontAwesomeIcon icon={faUser} size="lg" />{' '}
      <FormattedHTMLMessage
        id={messages.patientInfo}
        values={{
          name: name || `(${formatMessage({ id: messages.noName })})`,
          nationalId:
            nationalId || `(${formatMessage({ id: messages.noNationalId })})`
        }}
      />
    </Alert>
    {/* Display the patient's portal eAsiointi agreement status */}
    {agreement == null ? null :
     agreement ?
       <Alert bsStyle="success">
         <FormattedHTMLMessage
           id={messages.agreementTrue}
         />
       </Alert> :
       <Alert bsStyle="warning">
         <FormattedHTMLMessage
           id={messages.agreementFalse}
         />
       </Alert>
    }
    </>
  )) |> injectIntl
);
