import indices from './indices';
import * as FIELD_TYPE from './fieldTypes';

export const fields = {
  TITLE: {
    NAME: { fi: 'Nimi' },
    TYPE: FIELD_TYPE.TEXT,
    LOCALIZE: true
  },
  NOTES: {
    NAME: { fi: 'Muistiinpanot' },
    TYPE: FIELD_TYPE.TEXTAREA,
    HELP: {
      fi: 'Muistiipanot näkyvät ainoastaan lomakkeen laatijalle.'
    }
  },
  ASSIGNABLE: {
    NAME: { fi: 'Kohdistettava' },
    TYPE: FIELD_TYPE.BOOLEAN,
    HELP: {
      fi: 'Näkyykö lomake ammattilaiselle kohdistusnäkymässä.'
    }
  },
  SHOW_LETTERS: {
    NAME: { fi: 'Näytä kirjaimet' },
    TYPE: FIELD_TYPE.BOOLEAN
  },
  ELEMENTS: {
    LIST: true,
    NAME: { fi: 'Elementit' },
    TYPE: FIELD_TYPE.SELECT_ELEMENT
  },
  NAME: {
    NAME: { fi: 'Nimi' },
    TYPE: FIELD_TYPE.TEXT,
    LOCALIZE: true
  },
  DESCRIPTION: {
    NAME: { fi: 'Kuvaus' },
    TYPE: FIELD_TYPE.TEXT,
    LOCALIZE: true
  },
  HELP: {
    NAME: { fi: 'Ohje' },
    TYPE: FIELD_TYPE.TEXT,
    LOCALIZE: true
  },
  SELECT_WIDGET: {
    NAME: { fi: 'Näyttötyyppi' },
    TYPE: FIELD_TYPE.SELECT,
    PLACEHOLDER: { fi: 'Valintalista' },
    CHOICES: [{ fi: 'Radiopainikkeet' }]
  },
  MULTISELECT_WIDGET: {
    NAME: { fi: 'Näyttötyyppi' },
    TYPE: FIELD_TYPE.SELECT,
    PLACEHOLDER: { fi: 'Monivalintalista' },
    CHOICES: [{ fi: 'Valintaruudut' }]
  },
  CHOICES: {
    NAME: { fi: 'Vaihtoehdot' },
    TYPE: FIELD_TYPE.TEXT,
    LIST: true,
    LOCALIZE: true
  },
  CHOICES_DISABLED: {
    NAME: { fi: 'Ei-valittavat vaihtoehdot' },
    TYPE: FIELD_TYPE.TEXT,
    LIST: true,
    LOCALIZE: true
  },
  TYPE: {
    NAME: { fi: 'Tyyppi' },
    TYPE: FIELD_TYPE.SELECT,
    CHOICES: FIELD_TYPE.types,
    PLACEHOLDER: { fi: 'Valitse tyyppi' }
  },
  PLACEHOLDER: {
    NAME: { fi: 'Paikkateksti' },
    TYPE: FIELD_TYPE.TEXT,
    LOCALIZE: true
  },
  LOCALIZE: {
    NAME: { fi: 'Käännettävissä' },
    TYPE: FIELD_TYPE.BOOLEAN
  },
  LIST: {
    NAME: { fi: 'Toistettava' },
    TYPE: FIELD_TYPE.BOOLEAN,
    BOOLEAN_WIDGET: 2,
    BOOLEAN_DEFAULT: false,
    YES: {
      fi: 'Kyllä',
      en: 'Yes'
    },
    NO: {
      fi: 'Ei',
      en: 'No'
    }
  },
  BOOLEAN_DEFAULT: {
    NAME: { fi: 'Alkuarvo' },
    TYPE: FIELD_TYPE.BOOLEAN,
    BOOLEAN_WIDGET: 3
  },
  BOOLEAN_WIDGET: {
    NAME: { fi: 'Näyttötyyppi' },
    TYPE: FIELD_TYPE.SELECT,
    PLACEHOLDER: { fi: 'Valintapainike' },
    CHOICES: [
      { fi: 'Radiopainikkeet' },
      { fi: 'Valintalista' },
      { fi: 'Painike' },
      { fi: 'Painike, valinnainen' }
    ]
  },
  NO: {
    NAME: { fi: 'Ei' },
    TYPE: FIELD_TYPE.TEXT,
    LOCALIZE: true
  },
  YES: {
    NAME: { fi: 'Kyllä' },
    TYPE: FIELD_TYPE.TEXT,
    LOCALIZE: true
  },
  PATTERN: {
    NAME: { fi: 'Formaatti' },
    TYPE: FIELD_TYPE.TEXT,
    HELP: {
      fi: 'Esimerkiksi vain numeroita, syötä: ^\\d*$'
    }
  },
  PATTERN_DESCRIPTION: {
    NAME: { fi: 'Formaatin selitys' },
    TYPE: FIELD_TYPE.TEXT,
    LOCALIZE: true,
    HELP: {
      fi:
        'Näytetään validointivirheen yhteydessä ("{kysymys}" arvon on oltava {selitys})'
    }
  },
  DATE_DISABLE_BEFORE: {
    NAME: { fi: 'Estä valinta ennen päivämäärää' },
    TYPE: FIELD_TYPE.TEXT,
    HELP: {
      fi: '"today" tai esimerkiksi "2018-10-08"'
    }
  },
  DATE_DISABLE_AFTER: {
    NAME: { fi: 'Estä valinta päivämäärän jälkeen' },
    TYPE: FIELD_TYPE.TEXT,
    HELP: {
      fi: '"today" tai esimerkiksi "2018-10-08"'
    }
  },
  USE_LABEL: {
    NAME: {
      fi: 'Erillinen otsikko'
    },
    TYPE: FIELD_TYPE.BOOLEAN,
    HELP: {
      fi: 'Asetetaanko elementille muokattu otsikko.'
    }
  },
  LABEL: {
    NAME: {
      fi: 'Otsikko'
    },
    TYPE: FIELD_TYPE.TEXT,
    LOCALIZE: true,
    HELP: {
      fi: 'Näytetään elementin otsikkona sen nimen sijaan.'
    }
  },
  POINTS: {
    NAME: {
      fi: 'Pisteytys'
    },
    TYPE: FIELD_TYPE.BOOLEAN,
    HELP: {
      fi: 'Lasketaanko pisteytettyjen kenttien pistemäärät yhteen?'
    }
  },
  POINTS_TYPE: {
    NAME: {
      fi: 'Pisteytystyyppi'
    },
    TYPE: FIELD_TYPE.SELECT,
    HELP: {
      fi: 'Lasketaanko pistemäärä numeroina yhteen, käytetäänkö kirjainlippuja vai lasketaanko BMI-arvo? (BMI olettaa, että kysymysryhmässä on 2 kysymystä: Pituus ja paino)'
    },
    PLACEHOLDER: { fi: 'Valitse pisteytystyyppi' },
    CHOICES: [
      { id: 'NUMBER', fi: 'Numerot' },
      { id: 'LETTER', fi: 'Kirjaimet' },
      { id: 'BMI', fi: 'BMI'}
    ]
  },
  SHOW_POINTS: {
    NAME: {
      fi: 'Näytä pisteytys'
    },
    TYPE: FIELD_TYPE.BOOLEAN,
    HELP: {
      fi: 'Näytetäänkö pisteiden yhteismäärä lomakkeen täyttäjälle?'
    }
  },
  CHOICES_POINTS: {
    NAME: {
      fi: 'Vaihtoehtojen pisteytys'
    },
    TYPE: FIELD_TYPE.TEXT,
    LIST: true,
    LOCALIZE: false
  },
  POINT_TRESHOLDS: {
    NAME: {
      fi: 'Pisterajat'
    },
    TYPE: FIELD_TYPE.STRING_PAIR,
    LIST: true
  },
  LABELS: {
    NAME: {
      fi: 'Otsikot'
    },
    TYPE: FIELD_TYPE.STRING_PAIR
  }
};

export default indices(fields);
