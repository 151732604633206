import React from 'react';
import connect from '../../../utils/react-refetch/connectAndRenderOnFulfilled';

export default ComposedComponent =>
  connect(({ id, rev }) => ({
    fieldsFetch: {
      url: `/api/field/forForm/${id}/${rev}`,
      andThen: () => {
        const loader = document.querySelector('#loader');

        if (loader) {
          loader.style.display = 'none';
        }
      }
    }
  }))(({ fieldsFetch, ...props }) => (
    <ComposedComponent {...props} fields={fieldsFetch} />
  ));
