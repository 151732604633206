import React, { Fragment } from 'react';
import { pickBy } from 'lodash';
import filter from '@visma/form-ula/es/filter';
import * as TYPE from '@visma/form-ula/es/constants/elementTypes';
import { BMI, LETTER, NUMBER } from "@visma/form-ula/es/constants/pointTypes";
import {
  FormGroup,
  ListGroup,
  ListGroupItem,
  Panel,
  Table
} from 'react-bootstrap';
import { FIELD } from '../../../../../formGroups';
import { FIELD as FIELDS_FIELD } from '../../../../../fields';
import withLocalize from '../../../../utils/withLocalize';
import { Consumer, DISPLAY_FORMAT } from '../ChooseDisplayFormat';
import { Fields } from './Fields';
import Type from './Field/Type';
import {hasRole, PATIENT, PROFESSIONAL} from "../../../../Role";

export default (({
  formGroup: {
    [FIELD.ELEMENTS]: elements,
    [FIELD.LIST]: list,
    [FIELD.POINTS]: points,
    [FIELD.POINTS_TYPE]: pointsType,
    [FIELD.SHOW_POINTS]: showPoints,
    [FIELD.POINT_TRESHOLDS]: pointTresholds,
    [FIELD.TITLE]: title
  },
  value,
  fields,
  formGroups,
  localize
}) => {

  let sum = null;

  const showPointsRoled = hasRole(PROFESSIONAL) ? true : showPoints;

  if (points && showPointsRoled && !elements.find(e => e.type === TYPE.POINTS) && value) {
    if (pointsType === NUMBER) {
      sum = 0;
      elements.forEach((e, index) => {
        const fieldVal = value[index];
        if (fieldVal === null || fieldVal === undefined) {
          return null;
        }
        const field = fields.find(field => field._id === e.id);

        if (!field || !field[FIELDS_FIELD.POINTS]) {
          return null;
        }

        if (Array.isArray(fieldVal)) {
          fieldVal.forEach(val => {
            const answerPoints = parseInt(field[FIELDS_FIELD.CHOICES_POINTS][val]);
            if (answerPoints > 0) {
              sum = sum + answerPoints;
            }
          });
        } else {
          const fieldPoints = field[FIELDS_FIELD.CHOICES_POINTS];
          const answerPoints = parseInt(fieldPoints?.[parseInt(fieldVal)]);
          if (answerPoints > 0) {
            sum = sum + answerPoints;
          }
        }
      });
      const sumIndex = Object.keys(elements).length;
      elements[sumIndex] = { id: null, type: TYPE.POINTS};

      const tresholds = pointTresholds ? pointTresholds[0].split(";") : null;

      let letterSum = [];
      if (tresholds && (parseInt(tresholds[0]) <= sum)) {
        const tresholdVal = tresholds[1].split("|");
        if (tresholdVal.length > 1) {
          tresholdVal[1].split('').forEach(letter => {
            if (letterSum.indexOf(letter) === -1) {
              letterSum.push(letter);
            }
          })
        }
      }

      if (hasRole(PROFESSIONAL)) {
        value[sumIndex] = sum.toString().concat(" ").concat(letterSum.sort().join(""));
      } else {
        value[sumIndex] = sum;
      }
    } else if (pointsType === LETTER) {
      sum = [];
      elements.forEach((e, index) => {
        const fieldVal = value[index];
        if (fieldVal === null || fieldVal === undefined) {
          return null;
        }
        const field = fields.find(field => field._id === e.id);

        if (!field || !field[FIELDS_FIELD.POINTS]) {
          return null;
        }

        if (Array.isArray(fieldVal)) {
          fieldVal.forEach(val => {
            const answerLetters = field[FIELDS_FIELD.CHOICES_POINTS][val];
            answerLetters?.split('').forEach(letter => {
              if (sum.indexOf(letter) === -1) {
                sum.push(letter);
              }
            });
          })
        } else {
          const fieldPoints = field[FIELDS_FIELD.CHOICES_POINTS];
          const answerLetters = fieldPoints?.[fieldVal];
          answerLetters?.split('').forEach(letter => {
            if (sum.indexOf(letter) === -1) {
              sum.push(letter);
            }
          });
        }
      });
      const sumIndex = Object.keys(elements).length;
      elements[sumIndex] = { id: null, type: TYPE.POINTS};
      value[sumIndex] = sum.sort().join("");
    } else if (pointsType === BMI) {
      sum = [];
      const fgValues = value;
      const tresholds = pointTresholds ? pointTresholds[0].split(";") : null;
      if (fgValues.length < 2 || fgValues[0] === undefined || fgValues[1] === undefined) {
        return null;
      }

      const length = parseFloat(fgValues[0]) / 100;
      const weight = parseFloat(fgValues[1]);

      const bmi = weight / length / length;

      if (tresholds && (parseInt(tresholds[0]) <= bmi)) {
        tresholds[1].split('').forEach(letter => {
          if (sum.indexOf(letter) === -1) {
            sum.push(letter);
          }
        })
      }
      const sumIndex = Object.keys(elements).length;
      elements[sumIndex] = { id: null, type: TYPE.POINTS};
      if (hasRole(PROFESSIONAL)) {
        value[sumIndex] = bmi.toFixed(1).toString().concat(" kg/m2 ").concat(sum.sort().join(""));
      } else {
        value[sumIndex] = bmi.toFixed(1).toString().concat(" kg/m2");
      }
    }
  }

  return (<Consumer>
    {displayFormat =>
      list
        ? do {
            ({
              [DISPLAY_FORMAT.CONDENSED]: () => {
                const columns = Object.entries(
                  filter({
                    elements: pickBy(elements, ({ type }) =>
                      [TYPE.FIELD].includes(type)
                    ),
                    formData: value
                  })
                );
                return (
                  <tr>
                    <td colSpan={2}>
                      {localize(title)}
                      <Table style={{ tableLayout: 'fixed' }}>
                        <thead>
                          <tr>
                            {columns && columns.length && columns.map(([_index, column]) => column)
                              .map(({ id }, index) => (
                                <th key={index}>
                                  {localize(
                                    fields.find(({ _id }) => _id === id)[
                                      [FIELDS_FIELD.NAME]
                                    ]
                                  )}
                                </th>
                              ))
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {value && value.length &&
                            value.map((value, index) => (
                              <tr key={index}>
                                {columns && columns.length && columns.map(([index, { id }]) => (
                                  <td key={index}>
                                    <Type
                                      field={fields.find(
                                        ({ _id }) => _id === id
                                      )}
                                      value={value[index]}
                                    />
                                  </td>
                                ))}
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                );
              },
              [DISPLAY_FORMAT.LIST]: () => (
                <>
                  <h4>{localize(title)}</h4>
                  <Panel.Body>
                    {value?.map((value, index) => (
                      <Fragment key={index}>
                        {index ? <hr /> : null}
                        <FormGroup key={index}>
                          <Fields
                            elements={elements}
                            fields={fields}
                            formGroups={formGroups}
                            values={value}
                          />
                        </FormGroup>
                      </Fragment>
                    ))}
                  </Panel.Body>
                </>
              ),
              [DISPLAY_FORMAT.PANEL]: () => (
                <Panel>
                  <Panel.Heading>{localize(title)}</Panel.Heading>
                  <ListGroup>
                    {value?.map((value, index) => (
                      <ListGroupItem key={index}>
                        <Fields
                          elements={elements}
                          fields={fields}
                          formGroups={formGroups}
                          values={value}
                        />
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Panel>
              ),
              [DISPLAY_FORMAT.TABLE]: () =>
                value &&
                value.map((valueEntry, index) => (
                  <tr key={index}>
                    {index ? null : (
                      <td rowSpan={value.length}>{localize(title)}</td>
                    )}
                    <td>
                      <Fields
                        elements={elements}
                        fields={fields}
                        formGroups={formGroups}
                        values={valueEntry}
                      />
                    </td>
                  </tr>
                ))
            }[displayFormat]());
          }
        : do {
            const element = (
              <Fields
                elements={elements}
                fields={fields}
                formGroups={formGroups}
                isNested
                values={value}
              />
            );
            ({
              [DISPLAY_FORMAT.CONDENSED]: () => element,
              [DISPLAY_FORMAT.LIST]: () => (
                <>
                  <h4>{localize(title)}</h4>
                  <Panel.Body>{element}</Panel.Body>
                </>
              ),
              [DISPLAY_FORMAT.PANEL]: () => (
                <Panel>
                  <Panel.Heading>{localize(title)}</Panel.Heading>
                  <Panel.Body>{element}</Panel.Body>
                </Panel>
              ),
              [DISPLAY_FORMAT.TABLE]: () => (
                <tr>
                  <td>{localize(title)}</td>
                  <td>{element}</td>
                </tr>
              )
            }[displayFormat]());
          }
    }
  </Consumer>
)}) |> withLocalize;
