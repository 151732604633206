/* eslint import/no-webpack-loader-syntax: "off" */
import languages from '@visma/react-intl-helpers/cjs/get-messages-file-list-loader!';

export default locale => {
  if (locale) {
    const [language, country] = locale.split('-');
    const countries = languages[language];
    if (countries) {
      return countries[country] || countries.default;
    }
  }
  return languages.default?.default;
};
