import { withRouter } from 'react-router-dom';

const RouterLocationState =
  (({ location: { pathname, search, hash, state }, children, history }) =>
    children({
      state,
      setState: nextState =>
        history.replace(`${pathname}${search}${hash}`, {
          ...state,
          ...nextState
        })
    })) |> withRouter;

export default RouterLocationState;

export const withRouterLocationState = Component => props => (
  <RouterLocationState>
    {routerStateProps => <Component {...props} {...routerStateProps} />}
  </RouterLocationState>
);
