import {Label, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import messages from './intl/messages.properties';
import React from "react";

const messageIDs = {
  NEW: 'statusNew',
  DRAFT: 'draft',
  SUBMITTED: 'submitted',
  DISABLED: 'disabled'
};

const styles = {
  NEW: 'info',
  DRAFT: 'warning',
  SUBMITTED: 'success',
  DISABLED: 'danger'
};

export default ({status, disableReason}) => (
  <OverlayTrigger
    placement="bottom"
    overlay={ status === "DISABLED" ?
      <Tooltip id="listitem-tooltip">
        {disableReason}
      </Tooltip> : <></>
    }
  >
    <Label bsStyle={styles[status]} style={{fontSize: 10.5}}>
      <FormattedMessage id={messages[messageIDs[status]]}/>
    </Label>
  </OverlayTrigger>
);
