import { pickBy } from 'lodash';
import { show } from '../../../../filter';

export default Base =>
  class ArrayField extends Base {
    renderArrayFieldItem(props) {
      return super.renderArrayFieldItem(
        props.itemSchema.type === 'object'
          ? do {
              const filterer = show({ formData: props.itemData });
              ({
                ...props,
                itemSchema: {
                  ...props.itemSchema,
                  properties: pickBy(
                    props.itemSchema.properties,
                    (property, id) =>
                      filterer(
                        props.itemUiSchema?.[id]?.['ui:options']?.element
                      )
                  )
                }
              });
            }
          : props
      );
    }
  };
