import { FormattedMessage } from 'react-intl';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import messages from './intl/messages.properties';

export default ({ id, onChange, options: { required }, value }) => {
  const inputValue = value === undefined ? 'default' : value ? 'yes' : 'no';
  return (
    <ToggleButtonGroup
      type="radio"
      name={id}
      value={inputValue}
      onChange={value =>
        onChange(
          {
            yes: true,
            no: false,
            default: undefined
          }[value]
        )
      }
    >
      <ToggleButton
        value={required ? 'default' : 'yes'}
        bsStyle={
          (required
          ? inputValue === 'default'
          : inputValue === 'yes')
            ? 'info'
            : undefined
        }
      >
        <FormattedMessage id={required ? messages.yesDefault : messages.yes} />
      </ToggleButton>
      <ToggleButton value={!required ? 'default' : 'no'}>
        <FormattedMessage id={!required ? messages.noDefault : messages.no} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

/*
3 state variant:

<ToggleButton
  value="yes"
  bsStyle={inputValue === 'yes' ? 'info' : undefined}
>
  <FormattedMessage id={messages.yes} />
</ToggleButton>
<ToggleButton value="no">
  <FormattedMessage id={messages.no} />
</ToggleButton>
<ToggleButton
  value="default"
  bsStyle={inputValue === 'default' && required ? 'info' : undefined}
>
  <FormattedMessage
    id={required ? messages.defaultYes : messages.defaultNo}
  />
</ToggleButton>
*/
