import { FIELD } from '../../../../formGroups';

export default ({ elements, list, points, pointsType, showPoints, pointTresholds, notes, title, ...rest }) => ({
  ...rest,
  [FIELD.ELEMENTS]: elements,
  [FIELD.LIST]: list,
  [FIELD.POINTS]: points,
  [FIELD.POINTS_TYPE]: pointsType,
  [FIELD.SHOW_POINTS]: showPoints,
  [FIELD.POINT_TRESHOLDS]: pointTresholds,
  [FIELD.NOTES]: notes,
  [FIELD.TITLE]: title
});
