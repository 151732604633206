import { intersection, isEqual, mapValues, pickBy } from 'lodash';
import pickDeepBy from './utils/pickDeepBy';

const filterPropFunction = propName => ({ elements = [], formData = {} }) =>
  function filterElement({ filter: { [propName]: propValue } = {} } = {}) {
    return propValue
      ? // Check if target element is also shown.
        filterElement(elements[propValue.target]) &&
          do {
            const value = formData[propValue.target];
            (function is({
              anyOf,
              exists,
              equals,
              includesEvery,
              includesSome
            }) {
              return anyOf
                ? anyOf.some(is)
                : exists
                ? value !== undefined
                : equals === undefined
                ? includesEvery === undefined
                  ? includesSome === undefined
                    ? true
                    : intersection(value, includesSome).length
                  : intersection(value, includesEvery).length ===
                    includesEvery.length
                : isEqual(
                    pickDeepBy(value, value => value !== undefined),
                    equals
                  );
            })(propValue);
          }
      : true;
  };

export const show = filterPropFunction('show');
export const enable = filterPropFunction('enable');

const filter = ({ elements, formData = {} }) =>
  mapValues(
    pickBy({ ...elements }, show({ elements, formData })),
    (element, index) =>
      // element.list element is filtered in ArrayField component.
      element.list
        ? element
        : {
            ...element,
            elements: filter({
              elements: element.elements,
              formData: formData[index]
            })
          }
  );

export default filter;
