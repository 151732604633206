import React, { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Localize } from '@visma/react-intl-helpers';
import FormULA from '@visma/form-ula';
import moment from 'moment';
import { Provider } from '../components/App/Authorize';
import SubmitWasSuccessful from '../components/SubmitWasSuccessful';
import connect from '../utils/react-refetch/connectAndRenderOnFulfilled';
import { setHost, getURL } from '../utils/react-refetch';
import messages from '../components/intl/messages.properties';
import AnswersView from '../components/AnswersView';
import { CustomFieldsSchema, CustomFieldsUISchema } from '../components/CustomFields';
import withFormFields from '../components/Pages/Fields/withFormFields';
import withFormFormGroups from '../components/Pages/FormGroups/withFormFormGroups';
import { convertFormData as convertFieldData } from '../fields';
import { convertFormData as convertFormgroupData } from '../formGroups';

const NEW = 'NEW';
const DRAFT = 'DRAFT';
const SUBMITTED = 'SUBMITTED';

const ANSWERS_VIEW = 'ANSWERS_VIEW';
const FORM_VIEW = 'FORM_VIEW';
const SUBMIT_WAS_SUCCESSFUL_VIEW = 'SUBMIT_WAS_SUCCESSFUL_VIEW';

const View = connect(({ id, rev, dataId }) => ({
  formFetch: `/api/form/${id}/${rev}`,
  save: data => ({
    saveResponse: {
      url: '/api/formdata',
      method: 'PUT',
      data,
      force: true,
      andThen: () => do {
        ({
          dataFetch: {
            url: `/api/formdata/${dataId}`,
            force: true,
            refreshing: true
          }
        });
      }
    }
  }),
  dataFetch: `/api/formdata/${dataId}`
}))(
  class View extends Component {
    state = {
      dataFetchRevs: []
    };

    constructor(props) {
      super(props);
      const { host } = props;
      if (host) {
        setHost(props.host);
      }
    }

    saveDraft = ({ formData }) => {
      this.save(formData, DRAFT);
      window.scrollTo(0, 0);
    };

    submit = ({ formData }) => {
      this.save(formData, SUBMITTED);
    };

    isNewRev = revToCheck => {
      const { dataFetchRevs } = this.state;

      return dataFetchRevs.filter(rev => rev === revToCheck).length <= 1;
    };

    save = (formData, status) => {
      const {
        props: { dataFetch: { _id, _rev } = {}, formFetch, save, dataId, token },
        state: { dataFetchRevs },
        isNewRev
      } = this;

      this.setState({ formData, status, dataFetchRevs: [ ...dataFetchRevs, _rev ] }, () => {
        if (isNewRev(_rev)) {
          save({
            _id,
            _rev,
            form: {
              id: formFetch._id,
              rev: formFetch._rev
            },
            timestamp: Date.now(),
            values: formData,
            status
          });
        } else {
          const fetchURL = getURL(`/api/formdata/${dataId}`);
          const fetchOptions = {
            headers: new Headers({
              Accept: 'application/json',
              Authorization: token,
              'Content-Type': 'application/json'
            })
          };

          fetch(fetchURL, fetchOptions)
            .then(response => response.json())
            .then(newDataFetch => {
              const {
                _id: fetchId,
                _rev: fetchRev,
                status: fetchStatus,
                error: fetchError,
                message: fetchMessage
              } = newDataFetch;

              const dataOk = (fetchStatus === DRAFT || fetchStatus === SUBMITTED);

              if (dataOk) {
                if (isNewRev(fetchRev)) {
                  this.setState({ dataFetchRevs: [ ...dataFetchRevs, fetchRev ] }, () => {
                    save({
                      _id: fetchId,
                      _rev: fetchRev,
                      form: {
                        id: formFetch._id,
                        rev: formFetch._rev
                      },
                      timestamp: Date.now(),
                      values: formData,
                      status
                    });
                  });
                }
              } else {
                console.log(`Data fetching error! Status: ${fetchStatus} ${fetchError}; Message: ${fetchMessage}`);
              }
            })
            .catch(error => {
              console.log('Data fetching error! ', error);
            });
        }
      });
    };

    getForm = formData => {
      const {
        props: { fields, formGroups, formFetch, saveResponse, dataFetch }
      } = this;

      return (
        <FormULA
          design={formFetch}
          disabled={dataFetch.status === SUBMITTED || saveResponse?.pending}
          isFetching={saveResponse?.pending}
          formData={formData}
          fields={fields.map(convertFieldData)}
          formGroups={formGroups.map(convertFormgroupData)}
          onSubmit={this.submit}
          onSaveDraft={this.saveDraft}
          submitButtonMessageId={messages.submit}
          extraFieldTypes={{
            schema: { ...CustomFieldsSchema },
            uiSchema: () => (
              { ...CustomFieldsUISchema }
            )
          }}
        />
      );
    };

    renderSubView = (subView, fields, formGroups) => {
      const {
        props: {
          dataFetch,
          formFetch,
          history,
          onSubmitRedirectTo
        },
        state: { formData },
        getForm
      } = this;

      const answersView = (
        <AnswersView
          formFetch={formFetch}
          dataFetch={dataFetch}
          fields={fields}
          formGroups={formGroups}
          values={dataFetch.values}
          onCloseRedirectTo={onSubmitRedirectTo}
          history={history}
        />
      );

      const formView = (
        <PrefillData
          id={formFetch._id}
          rev={formFetch._rev}
          isNew={!dataFetch.status || dataFetch.status === NEW}
          values={dataFetch.values}
        >
          {getForm}
        </PrefillData>
      );

      const submitWasSuccessfulView = (
        <SubmitWasSuccessful
          design={formFetch}
          fields={fields}
          formGroups={formGroups}
          values={formData}
          OtherButtons={() => (
            <Button
              bsStyle="primary"
              onClick={() => history.replace(onSubmitRedirectTo)}
            >
              <FormattedMessage id={messages.close}/>
            </Button>
          )}
        />
      );

      const errorView = (
        <Alert bsStyle="danger">
          <FormattedMessage id={messages.viewNotFound}/>
        </Alert>
      );

      switch (subView) {
        case ANSWERS_VIEW: return answersView;
        case FORM_VIEW: return formView;
        case SUBMIT_WAS_SUCCESSFUL_VIEW: return submitWasSuccessfulView;
        default: return errorView;
      }
    };

    render() {
      const {
        props: {
          fields,
          formGroups,
          dataFetch,
          locale,
          showAnswers
        },
        state: { status },
        renderSubView
      } = this;

      let showForm = !showAnswers && status !== SUBMITTED && dataFetch?.status !== SUBMITTED;
      let showSubmitWasSuccessful = !showAnswers && (status === SUBMITTED || dataFetch?.status === SUBMITTED);
      let subView;

      if (showAnswers) {
        subView = ANSWERS_VIEW;
      } else if (showForm) {
        subView = FORM_VIEW;
      } else if (showSubmitWasSuccessful) {
        subView = SUBMIT_WAS_SUCCESSFUL_VIEW;
      }

      return (
        <div className="form-ula">
          <Localize locale={locale} onChangeLocale={moment.locale}>
            <>
              {renderSubView(subView, fields, formGroups)}
            </>
          </Localize>
        </div>
      );
    }
  }
    |> withFormFields
    |> withFormFormGroups
    |> withRouter
);

const PrefillData = connect(({ id, rev, isNew }) =>
  isNew ? { data: `/api/formdata/latest/${id}/${rev}` } : {}
)(({ isNew, values, data, children }) =>
  children(isNew ? data.values : values)
);

export default ({ authToken, ...otherProps }) =>
  authToken ? (
    <Provider value={authToken}>
      <View {...otherProps} />
    </Provider>
  ) : (
    <View {...otherProps} />
  );
