import React from "react";
import IconButton from "./IconButton";

export default function AddButton(props) {
  const { className, onClick, disabled, ariaLabelMessageId: ariaLabelMessageIdProp, ...otherProps } = props;

  return (
    <div className="row">
      <p className={`col-xs-3 col-xs-offset-9 text-right ${className}`}>
        <IconButton
          type="info"
          icon="plus"
          className="btn-add col-xs-12"
          onClick={onClick}
          disabled={disabled}
          ariaLabelMessageId={ariaLabelMessageIdProp}
          {...otherProps}
        />
      </p>
    </div>
  );
}
