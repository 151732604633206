import {FormattedMessage} from 'react-intl';
import {Button} from 'react-bootstrap';
import Empty from '../../Empty';
import React, { Component } from 'react';
import handleChildrenFunc from "./handleChildrenFunc";

export default Form => class LocalizeSaveDraftButton extends Component {

  handleSubmit = (event) => {
    const { formData } = event;
    const { savingDraft } = this;

    if (savingDraft !== undefined && savingDraft) {
      this.savingDraft = false;
      this.setState(state => ({
        formData: { ...state?.formData, ...formData }
      }));
      this.props.onSaveDraft?.({ formData });
    } else {
      this.savingDraft = false;
      this.props.onSubmit({
        ...event,
        formData: { ...this.state?.formData, ...formData }
      });
    }
  };

  render() {

    const { saveDraftButtonMessageId,
      children = <Empty/>,
      formData,
      ...otherProps
    } = this.props;

    return (
      <Form formData={formData} {...otherProps} onSubmit={this.handleSubmit}  >
        {handleChildrenFunc(children, children => (
          <>
            {children}
            {saveDraftButtonMessageId && (
              <Button id="saveDraftButton" bsStyle="info" type="submit" onClick={() => {this.savingDraft = true; window.scrollTo(0,0)}}>
                <FormattedMessage id={saveDraftButtonMessageId}/>
              </Button>
            )}
          </>
        ))}
      </Form>
    )
  }

}
