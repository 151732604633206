import { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import messages from './intl/messages.properties';

class Filter extends Component {
  state = {};

  static getDerivedStateFromProps({
    location: { state: { value = '' } = {} }
  }) {
    return { value };
  }

  setValue = value => {
    this.props.history.replace({
      state: { value }
    });
    this.setState({ value });
  };

  handleChange = ({ target: { value } }) => this.setValue(value);

  clear = () => this.setValue('');

  render() {
    const {
      props: {
        children,
        path,
        intl: { formatMessage },
        items
      },
      state: { value }
    } = this;
    const searchWords = value.toLowerCase().split(' ');
    return (
      <>
        <FormGroup>
          <InputGroup>
            <FormControl
              placeholder={formatMessage({ id: messages.filter })}
              type="text"
              value={value}
              onChange={this.handleChange}
            />
            <InputGroup.Button>
              <Button onClick={this.clear} disabled={!value}>
                <FormattedMessage id={messages.clear} />
              </Button>
            </InputGroup.Button>
          </InputGroup>
        </FormGroup>
        {children({
          items: items.filter(
            item => do {
              const itemTextProperty = get(item, path, '').toLowerCase();
              searchWords.every(value => itemTextProperty.includes(value));
            }
          ),
          searchWords
        })}
      </>
    );
  }
}

export default Filter |> injectIntl |> withRouter;
