import * as TYPE from '@visma/form-ula/es/constants/elementTypes';
import indices from '@visma/form-ula/es/constants/indices';
import formDataConverter from './formDataConverter';
import { FIELD as EDITOR_FIELD } from './components/Editor/fields';

export const elements = {
  TITLE: { id: EDITOR_FIELD.TITLE, type: TYPE.FIELD },
  NOTES: { id: EDITOR_FIELD.NOTES, type: TYPE.FIELD },
  LIST: { id: EDITOR_FIELD.LIST, type: TYPE.FIELD },
  POINTS: { id: EDITOR_FIELD.POINTS, type: TYPE.FIELD },
  POINTS_TYPE: {
    id: EDITOR_FIELD.POINTS_TYPE,
    type: TYPE.FIELD,
    filter: {
      show: {
        target: 3,
        equals: true
      }
    }
  },
  SHOW_POINTS: {
    id: EDITOR_FIELD.SHOW_POINTS,
    type: TYPE.FIELD,
    filter: {
      show: {
        target: 3,
        equals: true
      }
    }
  },
  POINT_TRESHOLDS: {
    id: EDITOR_FIELD.POINT_TRESHOLDS,
    type: TYPE.FIELD,
    filter: {
      show: {
        target: 4,
        anyOf: [
          { equals: 0 },
          { equals: 1 },
          { equals: 2 }
        ]
      }
    }
  },
  ELEMENTS: { id: EDITOR_FIELD.ELEMENTS, type: TYPE.FIELD }
};

export default Object.values(elements);

export const FIELD = indices(elements);

export const convertFormData = formDataConverter(elements);
