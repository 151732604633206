import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import messages from '../../intl/messages.properties';
import Language from './Language';
import { Role, CONFIGURER } from '../../Role';

export default () => (
  <Navbar staticTop>
    <Navbar.Header>
      <Navbar.Brand style={{ padding: '5px' }}>
        <Link to="/">
          ️Form ULA{' '}
          <span
            role="img"
            aria-label=""
            style={{ fontSize: 'xx-large', lineHeight: 0 }}
          >
            🏎️
          </span>
          {/* <img
            src={require('../../../images/omaraitti-lomakepalvelu.png')}
            style={{height: "40px"}}
          /> */}
        </Link>
      </Navbar.Brand>
    </Navbar.Header>
    <Nav>
      <Role is={CONFIGURER}>
        <LinkContainer to="/forms">
          <NavItem eventKey={1}>
            <FormattedMessage id={messages.forms} />
          </NavItem>
        </LinkContainer>
        <LinkContainer to="/formgroups">
          <NavItem eventKey={2}>
            <FormattedMessage id={messages.formgroups} />
          </NavItem>
        </LinkContainer>
        <LinkContainer to="/fields">
          <NavItem eventKey={3}>
            <FormattedMessage id={messages.fields} />
          </NavItem>
        </LinkContainer>
        <Role isNot={CONFIGURER}>
          <LinkContainer to="/values">
            <NavItem eventKey={4}>
              <FormattedMessage id={messages.values} />
            </NavItem>
          </LinkContainer>
        </Role>
      </Role>
    </Nav>
    <Nav pullRight>
      <Language />
    </Nav>
  </Navbar>
);
