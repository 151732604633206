import { Component } from 'react';

export default Form =>
  class LiveValidateOnOnError extends Component {
    state = {};

    handleError = errors => {
      this.setState({ hasErrors: true });
      this.props.onError?.(errors);
    };

    render() {
      return (
        <Form
          liveValidate={this.state.hasErrors}
          {...this.props}
          onError={this.handleError}
        />
      );
    }
  };
