import React from "react";
import { injectIntl } from 'react-intl';
import messages from '../../../../intl/messages.properties';

export default injectIntl(function IconButton(props) {
  const { type = "default", icon, className, ariaLabelMessageId, intl: { formatMessage }, ...otherProps } = props;

  return (
    <button
      type="button"
      className={`btn btn-${type} ${className}`}
      aria-label={formatMessage({ id: messages[ariaLabelMessageId] })}
      {...otherProps}
    >
      <i className={`glyphicon glyphicon-${icon}`} />
    </button>
  );
});
