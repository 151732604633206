import ReactMarkdown from 'react-markdown';
import { createPageTitle } from '../../utils/react-jsonschema-form/Form/Pagination/PageTitle';
import FIELD from '../../constants/fieldIDs';
import * as ELEMENT_TYPE from '../../constants/elementTypes';
import * as FIELD_TYPE from '../../constants/fieldTypes';
import * as uiWidgetTypesForElementTypes from './uiWidgetTypesForElementTypes';

export const localizedText = { 'ui:field': 'TranslatableString' };

export default ({
  extraFieldTypes = {},
  fields = [],
  formGroups = [],
  localize
}) =>
  function elementToUISchema({ content, id, type }) {
    return {
      [ELEMENT_TYPE.BODY]: () => ({
        'ui:field': () => <ReactMarkdown source={localize(content)} />,
        'ui:should-update': content
      }),
      [ELEMENT_TYPE.BODY_PROF]: () => ({
        'ui:field': () => <ReactMarkdown source={localize(content)} />,
        'ui:should-update': content
      }),
      [ELEMENT_TYPE.FIELD]: () => do {
        const {
          [FIELD.CHOICES_DISABLED]: choicesDisabled,
          [FIELD.DESCRIPTION]: description,
          [FIELD.DATE_DISABLE_BEFORE]: disableBefore,
          [FIELD.DATE_DISABLE_AFTER]: disableAfter,
          [FIELD.HELP]: help,
          [FIELD.LIST]: list,
          [FIELD.LOCALIZE]: localizeField,
          [FIELD.PLACEHOLDER]: placeholder,
          [FIELD.TYPE]: type,
          [FIELD.MULTISELECT_WIDGET]: multiselectWidget,
          [FIELD.SELECT_WIDGET]: selectWidget,
          [FIELD.BOOLEAN_WIDGET]: booleanWidget,
          [FIELD.PATTERN_DESCRIPTION]: patternDescription
        } = fields.find(({ _id }) => _id === id) || {};

        props => {
          const item = {
            'ui:description': localize(description),
            'ui:help': localize(help),
            ...{
              [FIELD_TYPE.BOOLEAN]: {
                'ui:widget': {
                  0: 'radio',
                  1: 'select',
                  2: uiWidgetTypesForElementTypes.boolean.switch,
                  3: uiWidgetTypesForElementTypes.boolean.optionalSwitch
                }[booleanWidget]
              },
              [FIELD_TYPE.DATE]: {
                'ui:widget': uiWidgetTypesForElementTypes.date.default,
                'ui:options': {
                  disableBefore,
                  disableAfter
                }
              },
              [FIELD_TYPE.TEXT]: localizeField
                ? localizedText
                : { patternDescription },
              [FIELD_TYPE.TEXTAREA]: {
                ...(localizeField
                  ? { 'ui:field': 'TranslatableTextarea' }
                  : { patternDescription }),
                'ui:widget': 'textarea'
              },
              [FIELD_TYPE.RICHTEXT]: {
                ...(localizeField
                  ? { 'ui:field': 'TranslatableRichText' }
                  : undefined),
                'ui:widget': uiWidgetTypesForElementTypes.richText.default
              },
              [FIELD_TYPE.SLIDER]: {
                ...(localizeField
                  ? { 'ui:field': 'TranslatableSlider' }
                  : undefined),
                'ui:widget': uiWidgetTypesForElementTypes.slider.default
              },
              [FIELD_TYPE.SELECT]: {
                'ui:placeholder': localize(placeholder),
                'ui:widget': { 0: 'radio' }[selectWidget],
                'ui:enumDisabled': choicesDisabled
              },
              [FIELD_TYPE.MULTISELECT]: {
                'ui:placeholder': localize(placeholder),
                'ui:widget': { 0: 'checkboxes' }[multiselectWidget],
                'ui:enumDisabled': choicesDisabled
              },
              [FIELD_TYPE.STRING_PAIR]: {
                'ui:widget': uiWidgetTypesForElementTypes.stringPair.default
              },
              ...extraFieldTypes.uiSchema?.(props)
            }[type]
          };
          // Check `!item.items`, because extraFieldTypes may define `items`, `ui:field` and other properties.
          return list && !item.items ? { items: item } : item;
        };
      },
      [ELEMENT_TYPE.FORMGROUP]: () => do {
        const {
          [FIELD.ELEMENTS]: elements = [],
          [FIELD.LIST]: list,
          [FIELD.POINTS]: points,
          [FIELD.POINTS_TYPE]: pointsType,
          [FIELD.SHOW_POINTS]: showPoints,
          [FIELD.POINT_TRESHOLDS]: pointTresholds
        } = formGroups.find(({ _id }) => _id === id) || {};
        const item = {
          ...elements.filter(element => element).map(elementToUISchema)
        };
        if (points && showPoints) {
          item[Object.keys(item).length] = {
            'ui:widget': uiWidgetTypesForElementTypes.pointsDisplay.default,
            'ui:readonly': true,
            'ui:options': {
              label: false,
              pointsType,
              pointTresholds
            }

          };
        }
        ({
          ...(list ? { items: item } : item)
        });
      },
      [ELEMENT_TYPE.PAGE_TITLE]: () => createPageTitle(localize(content)),
      [ELEMENT_TYPE.TITLE]: () => ({
        'ui:field': () => <h4>{localize(content)}</h4>,
        'ui:should-update': content
      })
    }[type]();
  };
