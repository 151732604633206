import { mapValues } from 'lodash';

const getSchema = (schema, props) =>
  typeof schema === 'function' ? schema(props) : schema;

const getObjectSchema = (schema, props) =>
  schema.type === 'object'
    ? {
        ...schema,
        properties: mapValues(schema.properties, (propertySchema, name) =>
          getSchema(propertySchema, {
            ...props,
            formData: props.formData?.[name]
          })
        )
      }
    : schema;

const getObjectUISchema = (schema, uiSchema, props) => ({
  ...uiSchema,
  ...mapValues(schema.properties, (_, name) =>
    getSchema(uiSchema[name], {
      ...props,
      formData: props.formData?.[name]
    })
  )
});

export default props => {
  const schema = getObjectSchema(getSchema(props.schema, props), props);
  const uiSchema = getObjectUISchema(
    getSchema(schema, props),
    getSchema(props.uiSchema, props),
    props
  );
  return {
    ...props,
    schema,
    uiSchema
  };
};
