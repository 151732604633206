import * as TYPE from '@visma/form-ula/es/constants/fieldTypes';
import { FIELD } from '../../../../../../../fields';
import Boolean from './Boolean';
import Date from './Date';
import MultiSelect from './MultiSelect';
import Select from './Select';
import Text from './Text';
import Slider from './Slider';
import { CustomFieldsReadonly } from '../../../../../../CustomFields';
import Richtext from './Richtext'

const components = {
  [TYPE.BOOLEAN]: Boolean,
  [TYPE.DATE]: Date,
  [TYPE.TEXT]: Text,
  [TYPE.TEXTAREA]: Text,
  [TYPE.SELECT]: Select,
  [TYPE.MULTISELECT]: MultiSelect,
  [TYPE.SLIDER]: Slider,
  [TYPE.RICHTEXT]: Richtext,
  ...CustomFieldsReadonly
};

export default props => {
  const Type = components[props.field[FIELD.TYPE]];
  return <Type {...props} />;
};
