import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import messages from "./intl/messages.properties";
import common from '../../../../intl/messages.properties';
import withLocalize from "../../../../utils/withLocalize";


export default ((({disableModalOpen, setDisableModalOpen, setReasonModalOpen}) => (
    <>
      <Modal show={disableModalOpen !== false} onHide={() => setDisableModalOpen(false)}>
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id={messages.disableModalTitle}/>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormattedMessage id={messages.disableModalBody}/>
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="success" onClick={() => {
              setDisableModalOpen(false);
              setReasonModalOpen(true);
            }}
            >
              <FormattedMessage id={common.yes}/>
            </Button>
            <Button onClick={() => setDisableModalOpen(false)}>
              <FormattedMessage id={common.no}/>
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    </>
  )) |> withLocalize
);
