import { mapValues } from 'lodash';
import data from './data';
import field from './field';
import form from './form';
import formGroup from './formGroup';
import { getURLPath } from '../..';

export default connect => propsToOptions => Component =>
  connect(propsToOptions)(props => (
    <Component
      {...props}
      {...mapValues(
        propsToOptions(props),
        (option, name) =>
          typeof option === 'function'
            ? props[name]
            : do {
                const url = getURLPath(
                  typeof option === 'object' ? option.url : option
                );
                const { value } = props[name];
                ({
                  ...props[name],
                  value:
                    value &&
                    do {
                      /**
                       * Map response datas from different controllers to their respective objects
                       * For example:
                       *  /api/form -> form
                       *  /api/form/assignable -> form
                       */
                      const endpoint = [
                        { path: 'assigned', method: form },
                        { path: 'field', method: field },
                        { path: 'formdata', method: data },
                        { path: 'formgroup', method: formGroup },
                        { path: 'form', method: form }
                      ].find(({ path }) => (url.startsWith(`/api/${path}`)));
                      endpoint
                        ? do {
                            const { method, path } = endpoint;
                            Array.isArray(value)
                              ? value.map(method)
                              : method(value);
                          }
                        : value;
                    }
                });
              }
      )}
    />
  ));
