import React, { Fragment } from 'react';
import { Panel, Table } from 'react-bootstrap';
import { pickBy } from 'lodash';
import filter from '@visma/form-ula/es/filter';
import * as TYPE from '@visma/form-ula/es/constants/elementTypes';
import connect from '../../../../../utils/react-refetch/connectAndRenderOnFulfilled';
import withLocalize from '../../../../utils/withLocalize';
import { Consumer, DISPLAY_FORMAT } from '../ChooseDisplayFormat';
import Field from './Field';
import FormGroup from './FormGroup';
import ReactMarkdown from "react-markdown";

export const Fields =
  (({ elements, fields, formGroups, localize, isNested, values = {} }) => (
    <Consumer>
      {displayFormat => do {
        const element = Object.entries(
          filter({
            elements: pickBy(elements, ({ type }) =>
              [
                TYPE.FIELD,
                TYPE.FORMGROUP,
                TYPE.PAGE_TITLE,
                TYPE.TITLE,
                TYPE.POINTS,
                TYPE.BODY_PROF
              ].includes(type)
            ),
            formData: values
          })
        ).map(
          ([index, { id, content, type }]) => do {
            const element = {
              [TYPE.FIELD]: () => (
                <Field
                  field={fields.find(({ _id }) => _id === id)}
                  value={values[index]}
                />
              ),
              [TYPE.FORMGROUP]: () => (
                <FormGroup
                  fields={fields}
                  formGroup={formGroups.find(({ _id }) => _id === id)}
                  value={values[index]}
                />
              ),
              [TYPE.BODY_PROF]: () => (
                <tr>
                  <td>
                    <ReactMarkdown source={localize(content)} />
                  </td>
                </tr>
              ),
              [TYPE.PAGE_TITLE]: () => do {
                const element = <h3>{localize(content)}</h3>;
                ({
                  [DISPLAY_FORMAT.CONDENSED]: <tr><td colSpan="2">{element}</td></tr>,
                  [DISPLAY_FORMAT.LIST]: element,
                  [DISPLAY_FORMAT.PANEL]: element,
                  [DISPLAY_FORMAT.TABLE]: (
                    <tr>
                      <td>{element}</td>
                    </tr>
                  )
                }[displayFormat]);
              },
              [TYPE.TITLE]: () => do {
                const element = <h4>{localize(content)}</h4>;
                ({
                  [DISPLAY_FORMAT.CONDENSED]: null,
                  [DISPLAY_FORMAT.LIST]: element,
                  [DISPLAY_FORMAT.PANEL]: element,
                  [DISPLAY_FORMAT.TABLE]: (
                    <tr>
                      <td>{element}</td>
                    </tr>
                  )
                }[displayFormat]);
              },
              [TYPE.POINTS]: () => do {
              ({
                [DISPLAY_FORMAT.CONDENSED]: (
                  <tr>
                    <td>
                      <div style={{whiteSpace: 'pre-line'}}><b>Tulos</b></div>
                    </td>
                    <td><b>{values[index]}</b></td>
                  </tr>
                ),
                [DISPLAY_FORMAT.PANEL]: (
                  <Panel>
                    <Panel.Heading>
                      <div style={{whiteSpace: 'pre-line'}}><b>Tulos</b></div>
                    </Panel.Heading>
                    <Panel.Body><b>{values[index]}</b></Panel.Body>
                  </Panel>
                ),
              }[displayFormat])
            }
            }[type]();
            ({
              [DISPLAY_FORMAT.CONDENSED]: (
                <Fragment key={index}>{element}</Fragment>
              ),
              [DISPLAY_FORMAT.LIST]: <Fragment key={index}>{element}</Fragment>,
              [DISPLAY_FORMAT.PANEL]: (
                <Fragment key={index}>{element}</Fragment>
              ),
              [DISPLAY_FORMAT.TABLE]: <Fragment key={index}>{element}</Fragment>
            }[displayFormat]);
          }
        );
        ({
          [DISPLAY_FORMAT.CONDENSED]: isNested ? (
            element
          ) : (
            <Table style={{ tableLayout: 'fixed' }}>
              <tbody>{element}</tbody>
            </Table>
          ),
          [DISPLAY_FORMAT.LIST]: element,
          [DISPLAY_FORMAT.PANEL]: element,
          [DISPLAY_FORMAT.TABLE]: (
            <Table style={{ tableLayout: 'fixed' }}>
              <tbody>{element}</tbody>
            </Table>
          )
        }[displayFormat]);
      }}
    </Consumer>
  )) |> withLocalize;

export default connect(() => ({}))(Fields);
