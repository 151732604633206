import Form from './utils/react-jsonschema-form/Form';
import GetFormData from './utils/react-jsonschema-form/GetFormData';
import withLocalize from './utils/react-intl/withLocalize';
import elementsToSchema from './conversions/elementsToSchema';
import elementsToUISchema from './conversions/elementsToUISchema';
import FIELD from './constants/fieldIDs';

export default (({
  children,
  design: { [FIELD.TITLE]: title, [FIELD.ELEMENTS]: elements = [] } = {},
  extraFieldTypes,
  fields,
  formGroups,
  localize,
  disabled,
  ...otherProps
}) => (
  <GetFormData {...otherProps}>
    {props => {
      const conversionProps = {
        extraFieldTypes,
        fields,
        formGroups,
        localize
      };
      const conversionArgs = {
        elements,
        formData: props.formData
      };
      const uiSchema = elementsToUISchema(conversionProps)(conversionArgs);
      return (
        <Form
          {...props}
          schema={{
            title: localize(title),
            ...elementsToSchema(conversionProps)(conversionArgs)
          }}
          uiSchema={disabled ? {...uiSchema, 'ui:disabled': true} : uiSchema}
        >
          {children}
        </Form>
      );
    }}
  </GetFormData>
)) |> withLocalize;
