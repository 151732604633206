import FIELD from '../constants/fieldIDs';
import * as FIELD_TYPE from '../constants/fieldTypes';
import * as ELEMENT_TYPE from '../constants/elementTypes';
import elementsToSchema from './elementsToSchema';

export const localizedText = {
  type: 'object',
  properties: {
    fi: { type: 'string' },
    en: { type: 'string' }
  }
};

// Dummy schema for title/body element.
const any = () => ({ type: 'string' });

export default props => ({ id, required, type }, index) => {
  const {
    extraFieldTypes = {},
    fields = [],
    formGroups = [],
    localize
  } = props;
  return {
    [ELEMENT_TYPE.BODY]: any,
    [ELEMENT_TYPE.BODY_PROF]: any,
    [ELEMENT_TYPE.FIELD]: () => {
      const {
        [FIELD.CHOICES]: choices = [],
        [FIELD.LIST]: list,
        [FIELD.LOCALIZE]: localizeField,
        [FIELD.NAME]: name,
        [FIELD.TYPE]: type,
        [FIELD.BOOLEAN_DEFAULT]: booleanDefault,
        [FIELD.YES]: yes,
        [FIELD.NO]: no,
        [FIELD.PATTERN]: pattern,
        [FIELD.USE_LABEL]: useLabel,
        [FIELD.LABEL]: label,
        [FIELD.LABELS]: labels
      } = fields.find(({ _id }) => _id === id) || {};
      const localizable = localizeField
        ? localizedText
        : {
            pattern,
            type: ['string', 'null'],
            ...(required ? { minLength: 1 } : undefined)
          };
      const title = useLabel ? localize(label) : localize(name);
      const selectableItem = {
        type: 'integer',
        enum: choices.map((v,i) => v?.enum || i),
        enumNames: choices.map(v => localize(v?.enumNames || v) )
      };
      const item = {
        ...{
          [FIELD_TYPE.BOOLEAN]: {
            type: 'boolean',
            enumNames: [localize(yes), localize(no)],
            default: booleanDefault
          },
          [FIELD_TYPE.DATE]: {
            format: 'date',
            type: 'string'
          },
          [FIELD_TYPE.MULTISELECT]: {
            type: 'array',
            items: selectableItem,
            uniqueItems: true
          },
          [FIELD_TYPE.RICHTEXT]: localizable,
          [FIELD_TYPE.SLIDER]: selectableItem,
          [FIELD_TYPE.SELECT]: selectableItem,
          [FIELD_TYPE.TEXT]: localizable,
          [FIELD_TYPE.TEXTAREA]: localizable,
          [FIELD_TYPE.STRING_PAIR]: {
            type: 'string',
            labels
          },
          ...extraFieldTypes.schema
        }[type]
      };
      return {
        title,
        ...(list ? { type: 'array', items: item } : item)
      };
    },
    [ELEMENT_TYPE.FORMGROUP]: () => do {
      const {
        [FIELD.LIST]: list,
        [FIELD.POINTS]: points,
        [FIELD.SHOW_POINTS]: showPoints,
        [FIELD.TITLE]: title,
        [FIELD.USE_LABEL]: useLabel,
        [FIELD.LABEL]: label,
        [FIELD.ELEMENTS]: elements = []
      } = formGroups.find(({ _id }) => _id === id) || {};
      const formgroupFields = elements.map(e => fields.find(({ _id }) => _id === e.id));
      ({ formData }) => do {
        const item = elementsToSchema(props)({
          elements,
          formgroupFields,
          index,
          formData,
          points,
          showPoints
        });
        ({
          title: useLabel ? localize(label) : localize(title),
          ...(list ? { type: 'array', items: item } : item)
        });
      };
    },
    [ELEMENT_TYPE.PAGE_TITLE]: any,
    [ELEMENT_TYPE.TITLE]: any
  }[type]?.();
};
