import React from 'react';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import {ListGroup, Table} from 'react-bootstrap';
import {FIELD} from '../../../../../forms';
import Filter from '../../../../Filter';
import Header from '../../../../lists/ListGroupItem/Header';
import {getLanguage} from '../../../../utils/withLocalize';
import Status from './Status';
import Timestamp from '../../../../lists/Timestamp';
import ButtonShow from './ButtonShow';
import messages from "./intl/messages.properties";
import {State} from "react-powerplug";
import ButtonDisable from "./ButtonDisable";
import ReasonModal from "./ReasonModal";
import DisableConfirmModal from "./DisableConfirmModal";

const RefLink = () => null;

export default (({forms, refreshForms, intl: {locale}}) => do {
  <State initial={{disableFormId: null, disableModalOpen: false, reasonModalOpen: false}}>
    {({state, setState}) => (
      <>
        <Filter items={forms} path={[FIELD.TITLE, getLanguage(locale)]}>
          {({items, searchWords}) => (
            <ListGroup className="home">
              <Table responsive>
                <thead>
                <tr>
                  <th className="timestamp">
                    <FormattedMessage id={messages.assigned}/>
                  </th>

                  <th className="timestamp">
                    <FormattedMessage id={messages.dateOfExpiry}/>
                  </th>

                  <th className="timestamp">
                    <FormattedMessage id={messages.filled}/>
                  </th>

                  <th className="status">
                    <FormattedMessage id={messages.status}/>
                  </th>

                  <th className="header">
                    <FormattedMessage id={messages.form}/>
                  </th>

                  <th className="additional-info">
                    <FormattedMessage id={messages.additionalInfo}/>
                  </th>

                  <th className="action-container">
                    <FormattedMessage id={messages.actions}/>
                  </th>
                </tr>
                </thead>

                <tbody>
                {items.sort((a, b) => b.assignedTimestamp - a.assignedTimestamp).map(item => (
                  <tr className="list-group-item" key={item.dataId}>
                    <td className="timestamp">
                      <Timestamp time={item.assignedTimestamp} status={item.status}/>
                    </td>

                    <td className="timestamp">
                      {item.dateOfExpiry && <FormattedDate
                        value={item.dateOfExpiry}
                        day="numeric"
                        month="numeric"
                        year="numeric"
                      />}
                    </td>

                    <td className="timestamp">
                      <Timestamp time={item.modifyTimestamp} status={item.status}/>
                    </td>

                    <td className="status">
                      <Status status={item.disabled ? "DISABLED" : item.status} disableReason={item.disableReason}/>
                    </td>

                    <td className="header">
                      <Header
                        name={item[FIELD.TITLE]}
                        searchWords={searchWords}
                      />

                      {item.revTitle ? item.revTitle : null}
                    </td>

                    <td className="additional-info">
                      {item.info}
                    </td>

                    <td className="action-container">
                      {
                        !item.disabled &&
                        <>
                          <ButtonDisable
                            bsStyle={"danger"}
                            form={item}
                            setDisableFormId={(formId) => {
                              setState({disableFormId: formId})
                            }}
                            setDisableModalOpen={(open) => {
                              setState({disableModalOpen: open})
                            }}
                          /> <ButtonShow form={item}/>
                        </>
                      }
                    </td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </ListGroup>
          )}
        </Filter>
        <DisableConfirmModal
          disableModalOpen={state.disableModalOpen}
          setDisableModalOpen={(open) => {
            setState({disableModalOpen: open})
          }}
          setReasonModalOpen={(open) => {
            setState({reasonModalOpen: open})
          }}
        />
        <ReasonModal
          disableFormId={state.disableFormId}
          reasonModalOpen={state.reasonModalOpen}
          setReasonModalOpen={(open) => {
            setState({reasonModalOpen: open})
          }}
          onDisable={() => refreshForms()}
        />
      </>
    )}
  </State>
}) |> injectIntl
