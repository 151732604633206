export const yes = '../../src/components/intl:yes';
export const no = '../../src/components/intl:no';
export const back = '../../src/components/intl:back';
export const cancel = '../../src/components/intl:cancel';
export const clear = '../../src/components/intl:clear';
export const close = '../../src/components/intl:close';
export const confirmRemoveFilter = '../../src/components/intl:confirmRemoveFilter';
export const edit = '../../src/components/intl:edit';
export const filter = '../../src/components/intl:filter';
export const form = '../../src/components/intl:form';
export const forms = '../../src/components/intl:forms';
export const formgroups = '../../src/components/intl:formgroups';
export const print = '../../src/components/intl:print';
export const formUsageCount = '../../src/components/intl:formUsageCount';
export const formgroupUsageCount = '../../src/components/intl:formgroupUsageCount';
export const fieldCount = '../../src/components/intl:fieldCount';
export const language = '../../src/components/intl:language';
export const newForm = '../../src/components/intl:newForm';
export const noName = '../../src/components/intl:noName';
export const noNationalId = '../../src/components/intl:noNationalId';
export const agreementFalse = '../../src/components/intl:agreementFalse';
export const agreementTrue = '../../src/components/intl:agreementTrue';
export const note = '../../src/components/intl:note';
export const noteHelp = '../../src/components/intl:noteHelp';
export const ok = '../../src/components/intl:ok';
export const patientInfo = '../../src/components/intl:patientInfo';
export const fields = '../../src/components/intl:fields';
export const remove = '../../src/components/intl:remove';
export const save = '../../src/components/intl:save';
export const submit = '../../src/components/intl:submit';
export const values = '../../src/components/intl:values';
export const valuesAdded = '../../src/components/intl:valuesAdded';
export const contextNotFound = '../../src/components/intl:contextNotFound';
export const labStudies = '../../src/components/intl:labStudies';
export const viewNotFound = '../../src/components/intl:viewNotFound';
export const submitWasSuccessful = '../../src/components/intl:submitWasSuccessful';
export default {yes, no, back, cancel, clear, close, confirmRemoveFilter, edit, filter, form, forms, formgroups, print, formUsageCount, formgroupUsageCount, fieldCount, language, newForm, noName, noNationalId, agreementFalse, agreementTrue, note, noteHelp, ok, patientInfo, fields, remove, save, submit, values, valuesAdded, contextNotFound, labStudies, viewNotFound, submitWasSuccessful};
