import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { getLanguage } from '../../../react-intl/withLocalize';
import { Provider } from './context';

class TranslatableStringLanguageProvider extends Component {
  state = { value: getLanguage(this.props.intl.locale) };

  onChange = value => this.setState({ value });

  render() {
    const {
      onChange,
      props: { children },
      state: { value }
    } = this;
    return <Provider value={{ value, onChange }}>{children}</Provider>;
  }
}

export default TranslatableStringLanguageProvider |> injectIntl;
