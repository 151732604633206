import { FIELD } from '../../../../fields';

export default ({
  [String(FIELD.LIST)]: list,
  [String(FIELD.TYPE)]: type,
  [String(FIELD.NAME)]: name,
  [String(FIELD.DESCRIPTION)]: description,
  [String(FIELD.HELP)]: help,
  [String(FIELD.NOTES)]: notes,
  [String(FIELD.SELECT_WIDGET)]: selectWidget,
  [String(FIELD.MULTISELECT_WIDGET)]: multiselectWidget,
  [String(FIELD.POINTS)]: points,
  [String(FIELD.CHOICES)]: choices,
  [String(FIELD.CHOICES_POINTS)]: choicesPoints,
  [String(FIELD.LABELS)]: labels,
  [String(FIELD.PATTERN)]: pattern,
  [String(FIELD.PATTERN_DESCRIPTION)]: patternDescription,
  ...rest
}) => ({
  ...rest,
  list,
  type,
  name,
  description,
  help,
  notes,
  selectWidget,
  multiselectWidget,
  points,
  choices,
  choicesPoints,
  labels,
  pattern,
  patternDescription
});
