
const CustomFields = {};

export default CustomFields;

const getList = param => Object.keys(CustomFields).reduce(
  (a, v) => ({...a, [CustomFields[v].Type]: CustomFields[v][param]}), {}
);

export const CustomFieldsReadonly = getList('Read');
export const CustomFieldsUISchema = getList('uiSchema');
export const CustomFieldsSchema = getList('schema');
export const CustomFieldsTypes = Object.keys(CustomFields).reduce((a, v) => [...a, CustomFields[v].type], []);
