import { FormControl } from 'react-bootstrap';
import createTranlatableField from './createTranlatableField';

export default createTranlatableField(({ onChange, value }) => (
  <FormControl
    type="text"
    value={value}
    onChange={({ target: { value } }) => onChange(value)}
  />
));
