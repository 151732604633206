import { createContext } from 'react';

export const { Provider, Consumer } = createContext();

export default Form =>
  class FormData extends Form {
    render() {
      return <Provider value={{formData: this.state.formData, onChange: this.props.onChange}}>{super.render()}</Provider>;
    }
  };
