import { Route } from 'react-router-dom';
import TranslatableStringLanguageProvider from '@visma/form-ula/es/utils/react-jsonschema-form/fields/Translatable/LanguageProvider';
import Home from './Home';
import Values from './Values';
import FormGroups from './FormGroups';
import Forms from './Forms';
import Fields from './Fields';

export default () => (
  <>
    <Route exact path="/" component={Home} />
    <TranslatableStringLanguageProvider>
      <Route path="/formgroups" component={FormGroups} />
      <Route path="/forms" component={Forms} />
      <Route path="/fields" component={Fields} />
    </TranslatableStringLanguageProvider>
    <Route path="/values" component={Values} />
  </>
);
