import { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { negate } from 'lodash';
import FormULA from '@visma/form-ula';
import FIELD from '@visma/form-ula/es/constants/fieldIDs';
import * as ELEMENT from '@visma/form-ula/es/constants/elementTypes';
import * as TYPE from '@visma/form-ula/es/constants/fieldTypes';
import elementToSchema from '@visma/form-ula/es/conversions/elementToSchema';
import common from '../../intl/messages.properties';
import withLocalize from '../../utils/withLocalize';
import messages from './intl/messages.properties';

const isField = ({ element: { type } = {} }) => type === ELEMENT.FIELD;
const indexedElement = (element, index) => ({
  element,
  index
});

// Target element index: index of elements array (value in configuration)
// Field index: index of elements array, with only fields (selectable in UI)
const targetElementIndexToFieldIndex = ({ elements }) => target =>
  elements
    .map(indexedElement)
    .filter(isField)
    .findIndex(({ index }) => index === target);

const fieldIndexToTargetElementIndex = ({ elements }) => field =>
  elements.map(indexedElement).filter(isField)[field].index;

const convertToFormULAformData = ({
  elements,
  formData: { enable, show } = {}
}) => do {
  const value = enable || show;
  ({
    ...(value && {
      0: true,
      ...do {
        const { equals, includesEvery, target } = value;
        target === undefined
          ? undefined
          : {
              1: !!show,
              2: targetElementIndexToFieldIndex({ elements })(target),
              ...do {
                ({ 3: includesEvery || equals, 4: true });
              }
            };
      }
    })
  });
};

class RulesModal extends Component {
  static getDerivedStateFromProps(nextProps, { formData, prevProps }) {
    return {
      formData:
        nextProps === prevProps
          ? formData
          : convertToFormULAformData(nextProps),
      prevProps: nextProps
    };
  }

  state = {};

  handleSubmit = ({
    formData: { 0: hasRules, 1: hide, 2: fieldIndex, 3: value, 4: every }
  }) => {
    const {
      props: { elements, fields, formGroups, handleClose, localize, onChange }
    } = this;
    onChange(
      do {
        !hasRules || fieldIndex === undefined
          ? {}
          : do {
              const target = fieldIndexToTargetElementIndex({ elements })(
                fieldIndex
              );
              const element = elements[target];
              const schema = elementToSchema({ fields, formGroups, localize })(
                element
              );
              const { type } = schema;
              const isArray = type === 'array';
              ({
                [hide ? 'show' : 'enable']: {
                  target,
                  [isArray
                    ? every
                      ? 'includesEvery'
                      : 'includesSome'
                    : 'equals']: value
                }
              });
            };
      }
    );
    handleClose();
  };

  handleChange = ({ formData }) => this.setState({ formData });

  render() {
    const {
      props: {
        elements,
        fields,
        formGroups,
        handleClose,
        index,
        localize,
        show
      },
      state: { formData, formData: { 0: hasRules, 2: fieldIndex } = {} }
    } = this;

    return (
      <Modal show={show} onHide={handleClose}>
        <FormULA
          design={{
            [FIELD.ELEMENTS]: [
              {
                type: ELEMENT.FIELD,
                id: 0
              },
              ...(hasRules
                ? [
                    {
                      type: ELEMENT.FIELD,
                      id: 1
                    },
                    {
                      type: ELEMENT.FIELD,
                      id: 2
                    },
                    ...(fieldIndex === undefined
                      ? []
                      : do {
                          const { type } = elementToSchema({
                            fields,
                            formGroups,
                            localize
                          })({
                            type: ELEMENT.FIELD,
                            id: fieldIndex
                          });
                          [
                            {
                              type: ELEMENT.FIELD,
                              id: 3
                            },
                            ...(type === 'array'
                              ? [
                                  {
                                    type: ELEMENT.FIELD,
                                    id: 4
                                  }
                                ]
                              : [])
                          ];
                        })
                  ]
                : [])
            ]
          }}
          fields={[
            {
              [FIELD.TYPE]: TYPE.BOOLEAN,
              [FIELD.NAME]: {
                fi: 'Elementillä on näkyvyysrajoituksia'
              },
              [FIELD.BOOLEAN_WIDGET]: 2,
              [FIELD.BOOLEAN_DEFAULT]: false,
              [FIELD.YES]: {
                fi: 'Kyllä'
              },
              [FIELD.NO]: {
                fi: 'Ei'
              },
              _id: 0
            },
            {
              [FIELD.TYPE]: TYPE.BOOLEAN,
              [FIELD.NAME]: {
                fi: 'Rajoituksen tapa'
              },
              [FIELD.BOOLEAN_WIDGET]: 2,
              [FIELD.BOOLEAN_DEFAULT]: true,
              [FIELD.YES]: {
                fi: 'Piilotus'
              },
              [FIELD.NO]: {
                fi: 'Disablointi'
              },
              _id: 1
            },
            do {
              const indexedElements = elements.map(indexedElement);
              ({
                [FIELD.TYPE]: TYPE.SELECT,
                [FIELD.NAME]: {
                  fi: 'Kysymys'
                },
                [FIELD.CHOICES]: indexedElements
                  .filter(isField)
                  .map(({ element: { id }, ...other }) => ({
                    ...other,
                    field: fields.find(({ _id }) => _id === id)
                  }))
                  .map(({ field }) => field)
                  .filter(Boolean)
                  .map(({ [FIELD.NAME]: name }) => name),
                // .filter(({ field }) => field)
                // .map(({ field: { [FIELD.NAME]: name }, index }) => ({
                //   label: name,
                //   value: index
                // })),
                [FIELD.CHOICES_DISABLED]:
                  elements[index]?.type === ELEMENT.FIELD
                    ? [
                        Number(
                          index -
                            indexedElements
                              .filter(element => element.index <= index)
                              .filter(negate(isField)).length
                        )
                      ]
                    : undefined,
                _id: 2
              });
            },
            ...(fieldIndex === undefined || fieldIndex < 0
              ? []
              : [
                  {
                    ...do {
                      const { id } = elements[
                        fieldIndexToTargetElementIndex({ elements })(fieldIndex)
                      ];
                      fields.find(({ _id }) => _id === id);
                    },
                    _id: 3
                  }
                ]),
            {
              [FIELD.TYPE]: TYPE.BOOLEAN,
              [FIELD.NAME]: {
                fi: 'Valituista vaihtoehdoista pitää olla valittuna'
              },
              [FIELD.BOOLEAN_WIDGET]: 2,
              [FIELD.BOOLEAN_DEFAULT]: true,
              [FIELD.YES]: {
                fi: 'Kaikki'
              },
              [FIELD.NO]: {
                fi: 'Yksi tai useampi'
              },
              _id: 4
            }
          ]}
          formData={formData}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
        >
          {form => (
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <FormattedMessage id={messages.editRules} />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>{form}</Modal.Body>
              <Modal.Footer>
                <Button type="submit" bsStyle="primary">
                  <FormattedMessage id={common.ok} />
                </Button>
                <Button onClick={handleClose}>
                  <FormattedMessage id={common.cancel} />
                </Button>
              </Modal.Footer>
            </>
          )}
        </FormULA>
      </Modal>
    );
  }
}

export default RulesModal |> withLocalize;
