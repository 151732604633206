import * as TYPE from '@visma/form-ula/es/constants/elementTypes';
import indices from '@visma/form-ula/es/constants/indices';
import formDataConverter from './formDataConverter';
import { FIELD as EDITOR_FIELD } from './components/Editor/fields';

export const elements = {
  TITLE: { id: EDITOR_FIELD.TITLE, type: TYPE.FIELD },
  NOTES: { id: EDITOR_FIELD.NOTES, type: TYPE.FIELD },
  ASSIGNABLE: { id: EDITOR_FIELD.ASSIGNABLE, type: TYPE.FIELD },
  SHOW_LETTERS: {
    id: EDITOR_FIELD.SHOW_LETTERS,
    type: TYPE.FIELD
  },
  ELEMENTS: { id: EDITOR_FIELD.ELEMENTS, type: TYPE.FIELD },
  SUCCESS_TEXT: {
    id: EDITOR_FIELD.SUCCESS_TEXT,
    type: TYPE.FIELD
  }
};

export default Object.values(elements);

export const FIELD = indices(elements);

export const convertFormData = formDataConverter(elements);
