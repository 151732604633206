import jwtDecode from 'jwt-decode';
import { Consumer } from './App/Authorize';

export const CONFIGURER = 'configurer';
export const PATIENT = 'patient';
export const PROFESSIONAL = 'professional';

const DEBUG = false;

const rolesIncludeArray = (roles, include, method) =>
  include[method](role => roles.includes(role));

const rolesInclude = (roles, include, method) =>
  Array.isArray(include)
    ? rolesIncludeArray(roles, include, method)
    : rolesInclude(roles, [include]);

export const Role = ({ is, isNot, children }) => (
  <Consumer>
    {token => {
      const { roles = [] } = token ? jwtDecode(token) : {};
      return DEBUG
        ? children
        : is
          ? rolesInclude(is, roles, 'some')
            ? children
            : null
          : isNot
            ? !rolesInclude(isNot, roles, 'every')
              ? children
              : null
            : null;
    }}
  </Consumer>
);

export const hasRole = (is) => {
  const token = sessionStorage.getItem('token') || localStorage.getItem('token');
  if (!token) {
    return false;
  }
  const { roles = [] } = token ? jwtDecode(token) : {};
  return rolesInclude(is, roles, 'some');
}
