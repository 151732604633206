import { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import moment from 'moment';
import { injectIntl } from 'react-intl';
/* eslint import/no-webpack-loader-syntax: "off" */
/* eslint import/no-unresolved: "off" */
import '!style-loader!css-loader!react-dates/lib/css/_datepicker.css';
import messages from '../../../intl/messages.properties';

const parseToday = date => (date === 'today' ? moment() : moment(date));

class SingleDatePickerWidget extends Component {
  state = {};

  handleFocusChange = ({ focused }) => this.setState({ focused });

  render() {
    const {
      props: {
        id,
        intl: { formatMessage },
        onChange,
        options: { disableBefore, disableAfter } = {},
        value
      },
      state: { focused }
    } = this;
    return (
      <SingleDatePicker
        date={!value ? null : moment(value)}
        onDateChange={date => onChange(date && date.isValid() ? date.format('YYYY-MM-DD') : null)}
        focused={focused}
        readOnly
        onFocusChange={this.handleFocusChange}
        id={id}
        placeholder={formatMessage({ id: messages.date })}
        hideKeyboardShortcutsPanel
        isOutsideRange={m =>
          (disableBefore && m.isBefore(parseToday(disableBefore), 'day')) ||
          (disableAfter && m.isAfter(parseToday(disableAfter), 'day'))
        }
      />
    );
  }
}

export default SingleDatePickerWidget |> injectIntl;
