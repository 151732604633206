import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import Preview from './Preview';
import { wrapper, pane } from './styles.scss';

export default ({ left, right }) => (
  <ScrollSync>
    <div className={wrapper}>
      <ScrollSyncPane>
        <div className={pane}>{left}</div>
      </ScrollSyncPane>
      <ScrollSyncPane>
        <div className={pane}>
          <Preview>{right}</Preview>
        </div>
      </ScrollSyncPane>
    </div>
  </ScrollSync>
);
