/* eslint react/no-array-index-key: off */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, ListGroup, ListGroupItem } from 'react-bootstrap';
import messages from '../../../intl/messages.properties';

const ErrorList = function ErrorList(props) {
  const { errors } = props;
  return (
    <Alert bsStyle="danger">
      <h3>
        <FormattedMessage id={messages.errors} />
      </h3>

      <ListGroup>
        {errors.map((error, index) => (
          <ListGroupItem key={index} className="text-danger" role="alert">
            {error.stack}
          </ListGroupItem>
        ))}
      </ListGroup>
    </Alert>
  );
};

export default Form => props => <Form ErrorList={ErrorList} {...props} />;
