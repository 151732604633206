import React from 'react';
import { ListGroup } from 'react-bootstrap';
import connect from '../../../utils/react-refetch/connectAndRenderOnFulfilled';
import ListGroupItem from './ListGroupItem';

export default connect(() => ({
  dataFetch: '/api/formdata',
  formFetch: '/api/form',
  assignedForms: '/api/assigned'
}))(({ dataFetch, formFetch, assignedForms }) => (
  <ListGroup>
    {dataFetch.sort((a, b) => b.timestamp - a.timestamp ).map((item, index) => (
      <ListGroupItem key={index} item={item} forms={formFetch} assignedForms={assignedForms} />
    ))}
  </ListGroup>
));
