import React, { Fragment } from 'react';
import { isEmpty } from "lodash";
import { Label } from 'react-bootstrap';
import { FIELD } from '../../../../../../../fields';
import withLocalize from '../../../../../../utils/withLocalize';
import { Role, CONFIGURER, PATIENT, PROFESSIONAL } from '../../../../../../Role';

export default (({ localize, value, field: { [FIELD.CHOICES]: choices }, field}) =>
  (value && !isEmpty(value) && value.length && value.map(value => (
    <Fragment key={value}>
      <Label bsStyle="primary">{localize(choices[value])}</Label>{" "}

      <Role is={PATIENT}>
        {(field[FIELD.POINTS] && field[FIELD.SHOW_POINTS])
          ? <Label bsStyle="warning">{field[FIELD.CHOICES_POINTS][value]}</Label>
          : null
        }<br/>
      </Role>

      <Role is={PROFESSIONAL || CONFIGURER}>
        {field[FIELD.POINTS]
          ? <Label bsStyle="warning">{field[FIELD.CHOICES_POINTS][value]}</Label>
          : null
        }<br/>
      </Role>
    </Fragment>
  ))) || <></>) |> withLocalize;
