import { Label } from 'react-bootstrap';
import { FIELD } from '../../../../../../../fields';
import withLocalize from '../../../../../../utils/withLocalize';
import { Fragment } from 'react';
import { Role, CONFIGURER, PATIENT, PROFESSIONAL } from '../../../../../../Role';

export default (({ localize, value, field: { [FIELD.CHOICES]: choices } }, field) => (
  <Fragment>
    <Label bsStyle="primary">{localize(choices[value])}</Label>{" "}

    <Role is={PATIENT}>
      {(field[FIELD.POINTS] && field[FIELD.SHOW_POINTS])
        ? <Label bsStyle="warning">{field[FIELD.CHOICES_POINTS][value]}</Label>
        : null
      }
    </Role>

    <Role is={PROFESSIONAL || CONFIGURER}>
      {field[FIELD.POINTS]
        ? <Label bsStyle="warning">{field[FIELD.CHOICES_POINTS][value]}</Label>
        : null
      }
    </Role>
  </Fragment>
)) |> withLocalize;
