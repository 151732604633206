import * as widgets from '../widgets';

export default Form => props => (
  <Form
    {...props}
    widgets={{
      ...widgets,
      ...props.widgets
    }}
  />
);
