import {
  getDefaultRegistry,
  retrieveSchema,
  mergeObjects,
  toIdSchema
} from 'react-jsonschema-form/lib/utils';

export default props => {
  const { formData, registry = getDefaultRegistry() } = props;
  const { definitions } = registry;
  const { idSchema } = props;
  const schema = retrieveSchema(props.schema, definitions, formData);
  return mergeObjects(
    toIdSchema(schema, null, definitions, formData, idSchema.$id),
    idSchema
  );
};
