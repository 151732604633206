import React from 'react';
import {FormattedMessage} from 'react-intl';
import messages from './intl/messages.properties';
import ButtonWithoutHref from "../../../../utils/ButtonWithoutHref";

export default ({form, setDisableFormId, setDisableModalOpen, ...props}) => (
  <>
    <ButtonWithoutHref
      className="pull-right"
      onClick={
        () => {
          setDisableFormId(form.id);
          setDisableModalOpen(true);
        }
      }
      {...props}>
      <FormattedMessage id={messages.remove}/>
    </ButtonWithoutHref>
  </>
)
