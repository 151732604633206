import * as fields from '../fields';

export default Form => props => (
  <Form
    {...props}
    fields={{
      ...fields,
      ...props.fields
    }}
  />
);
