const { assign, create } = Object;

export default Base =>
  class ArrayField extends Base {
    constructor(props) {
      super(props);

      this.onAddClick = (...args) => {
        new Base(this.getModifiedProps()).onAddClick(...args);

        const targetNode = document.getElementById(`${this.props.idSchema.$id}`);
        const config = { childList: true, subtree: true };

        const callback = (mutationsList, observer) => {
          let inputs = document.querySelectorAll(`#${this.props.idSchema.$id} .array-item input, #${this.props.idSchema.$id} .array-item textarea`);

          for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].value == "") {
              inputs[i].focus();
              break;
            }
          }

          observer.disconnect();
        };

        const observer = new MutationObserver(callback);
        observer.observe(targetNode, config);
      }

      this.onDropIndexClick = index => (...args) => {
        new Base({
          ...this.getModifiedProps(),
          onChange: (...args) => {
            const [formData] = args;
            // If last item is removed, mark initial item dropped.
            if (!formData.length) {
              this.setState({ isDropped: true });
            }
            props.onChange(...args);
          }
        }).onDropIndexClick(index)(...args);
      };

      this.onChangeForIndex = index => (...args) =>
        new Base(this.getModifiedProps()).onChangeForIndex(index)(...args);
    }

    getModifiedProps() {
      const {
        props,
        props: { formData },
        state
      } = this;
      return {
        ...props,
        // Unless item last item is dropped, keep empty value in formData.
        formData: formData.length || state?.isDropped ? formData : [undefined]
      };
    }

    renderNormalArray() {
      return assign(create(this), {
        props: this.getModifiedProps()
      })::super.renderNormalArray();
    }
  };
