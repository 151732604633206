import { mapValues } from 'lodash';
import filter from '../filter';
import elementToSchema from './elementToSchema';

export default props => ({ elements, formgroupFields, index, formData, points, showPoints }) => do {
  const properties = mapValues(
    filter({
      elements,
      formData
    }),
    elementToSchema(props)
  );
  if (points && showPoints) {
    properties[Object.keys(elements).length] = {
      type: 'string',
      pattern: '.*',
      formgroupFields,
      index: index
    };
  }
  ({
    type: 'object',
    properties,
    required: Object.entries(elements)
      .filter(([_index, { required } = {}]) => required)
      .filter(([index]) => index in properties)
      .map(([index]) => index)
  });
};
