import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { get, identity, mapValues } from 'lodash';
import withLocalize from '../../../react-intl/withLocalize';
import resolveFunctionSchemas from '../../resolveFunctionSchemas';
import messages from './intl/messages.properties';
import type from './intl/type.properties';

const params = { type };

export default Form =>
  class LocalizeErrors extends Component {
    transformErrors = errors => do {
      const {
        intl: { formatMessage },
        localize,
        transformErrors = identity
      } = this.props;
      errors.map(
        error => do {
          const id = messages[error.name];
          id
            ? do {
                const { schema, uiSchema } = resolveFunctionSchemas(this.props);
                const errorProperty = error.property
                  // .properties[0].enum --> [0]
                  .replace(/^\.properties/, '')
                  .replace(/\.enum$/, '')
                  // Array item's array: ['0'][10] --> ['0']
                  // Drop index of repeatables: ['0'][10]['0'] -> ['0']['0']
                  .replace(/\[\d+\]/, '');
                const resolveFunction = x =>
                  typeof x === 'function' ? x() : x;
                const { fieldSchema, fieldUISchema } = errorProperty
                  .slice(1, -1)
                  .split('][')
                  .reduce(
                    function accumulator(
                      { fieldSchema, fieldUISchema },
                      pathPart
                    ) {
                      if (fieldSchema.type === 'object') {
                        return {
                          fieldSchema: get(
                            fieldSchema.properties,
                            `[${pathPart}]`
                          ),
                          fieldUISchema: resolveFunction(
                            get(fieldUISchema, `[${pathPart}]`)
                          )
                        };
                      }
                      if (fieldSchema.type === 'array') {
                        return accumulator(
                          {
                            fieldSchema: fieldSchema.items,
                            fieldUISchema: resolveFunction(fieldUISchema.items)
                          },
                          pathPart
                        );
                      }
                      return {
                        fieldSchema,
                        fieldUISchema: resolveFunction(fieldUISchema)
                      };
                    },
                    {
                      fieldSchema: schema,
                      fieldUISchema: resolveFunction(uiSchema)
                    }
                  );

                const { title } = fieldSchema;
                const { patternDescription } = fieldUISchema.items || fieldUISchema;
                const message = formatMessage(
                  { id },
                  {
                    field: title,
                    description: { pattern: localize(patternDescription) }[
                      error.name
                    ],
                    ...mapValues(error.params, (value, key) =>
                      key in params
                        ? formatMessage({ id: params[key][value] })
                        : value
                    )
                  }
                );
                ({
                  ...error,
                  message,
                  stack: message
                });
              }
            : error;
        }
      ) |> transformErrors;
    };

    render() {
      return <Form {...this.props} transformErrors={this.transformErrors} />;
    }
  }
  |> injectIntl
  |> withLocalize;
