import { Component, createContext } from 'react';
import { withRouter } from 'react-router-dom';
import { parse, stringify } from 'qs';
import { FormattedMessage } from 'react-intl';
import mode, { MOCK } from '../../mode';
import messages from '../intl/messages.properties';
import { getURL } from '../../utils/react-refetch';

const KEY = 'token';
export const { Provider, Consumer } = createContext();

export const withToken = Component => props => (
  <Consumer>{token => <Component {...props} token={token} />}</Consumer>
);

const Login = withRouter(
  class Login extends Component {
    state = {};

    async componentDidMount() {
      const {
        history,
        location: { pathname, search }
      } = this.props;
      const { sid, ...otherSearchParams } = parse(search.slice(1));
      if (sid) {
        const response = await fetch(getURL(`/api/login?sid=${sid}`));
        const search = stringify(otherSearchParams);
        sessionStorage.setItem(KEY, await response.text());

        history.replace(`${pathname}${search ? `?${search}` : ''}`);
      }
    }

    render() {
      const {
        children,
        location: { search }
      } = this.props;
      const { sid } = parse(search.slice(1));
      if (sid) {
        return null;
      }
      const token = sessionStorage.getItem(KEY) || localStorage.getItem(KEY);
      return token ? (
        <Provider value={token}>{children}</Provider>
      ) : (
        <h2 style={{ marginLeft: '20px' }}>
          <FormattedMessage id={messages.contextNotFound} />
        </h2>
      );
    }
  }
);

export default (mode === MOCK
  ? ({ children }) => children
  : ({ children }) => (
      <Consumer>
        {token => (token ? children : <Login>{children}</Login>)}
      </Consumer>
    ));
