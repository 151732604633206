export const TEXT = 0;
export const TEXTAREA = 1;
export const SELECT = 2;
export const SELECT_ELEMENT = 3;
export const MULTISELECT = 4;
export const BOOLEAN = 5;
export const DATE = 6;
export const RICHTEXT = 7;
export const SLIDER = 8;
export const STRING_PAIR = 9;

export const types = [
  { fi: 'Tekstikenttä' },
  { fi: 'Monirivinen tekstikenttä' },
  { fi: 'Valinta' },
  { fi: 'Kysymyksen valinta' },
  { fi: 'Monivalinta' },
  { fi: 'Boolean' },
  { fi: 'Päivämäärä' },
  { fi: 'Rikastettu tekstikenttä' },
  { fi: 'Liukusäädin' },
  { fi: 'Kaksoiskenttä' }
];
