import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import messages from '../../../intl/messages.properties';

const makeSwitch = (...extraButtons) =>
  (({ id, intl: { formatMessage }, onChange, options, value }) => {
    const formattedExtraButtons = extraButtons.map(({ id, ...option }) => ({
      label: formatMessage({ id }),
      ...option
    }));
    const inputValue = value === undefined ? 'empty' : value ? 'on' : 'off';
    return (
      <ToggleButtonGroup
        type="radio"
        name={id}
        value={inputValue}
        onChange={value =>
          onChange({ empty: undefined, on: true, off: false }[value])
        }
      >
        {[
          ...formattedExtraButtons,
          ...options.enumOptions.map(({ value, ...option }) => ({
            ...option,
            value: value ? 'on' : 'off'
          }))
        ].map(({ label, value }) => (
          <ToggleButton
            key={value}
            value={value}
            bsStyle={value === inputValue ? 'info' : undefined}
          >
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  }) |> injectIntl;

const switching = makeSwitch();
export { switching as switch };
export const optionalSwitch = makeSwitch({
  id: messages.emptyOption,
  value: 'empty'
});
