import {ButtonGroup, ButtonToolbar} from 'react-bootstrap';
import PatientInfo from '../../../PatientInfo';
import Assign from './Assign';
import List from './List';
import connect from '../../../../utils/react-refetch/connectAndRenderOnFulfilled';

export default connect(() => ({
  forms: '/api/assigned',
  refreshForms: () => ({
    forms: {
      url: '/api/assigned',
      force: true,
      refreshing: true
    }
  })
}))(({forms, refreshForms}) => (
  <>
    <PatientInfo/>
    <div className="pull-right">
      <ButtonToolbar>
        <ButtonGroup>​{/* Zero width space. */}</ButtonGroup>
        <ButtonGroup>
          <Assign onAssign={() => refreshForms()}/>
        </ButtonGroup>
      </ButtonToolbar>
    </div>
    <List forms={forms} refreshForms={refreshForms}/>
  </>
));
