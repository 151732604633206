import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';
import resolveFunctionSchemas from '../../resolveFunctionSchemas';

const objectOrFunc = PropTypes.oneOfType([PropTypes.object, PropTypes.func]);
Form.propTypes = {
  ...Form.propTypes,
  schema: objectOrFunc,
  uiSchema: objectOrFunc
};

export default Base =>
  class SchemaField extends Base {
    static propTypes = {
      ...Base.propTypes,
      schema: objectOrFunc,
      uiSchema: objectOrFunc
    };

    render() {
      const { props } = this;
      return {
        ...this,
        props: resolveFunctionSchemas(props)
      }::super.render();
    }
  };
